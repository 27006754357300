import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PageContainer = styled.div`
  padding: 20px;
`;


const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  gap: 10px;
  border: 1px solid #5333C1;
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-weight: bold;
  color: #333;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Description = styled.p`
  color: #666;
  line-height: 1.5;
`;

const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ComponentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const ComponentTitle = styled.h2`
  font-weight: bold;
  color: #333;
`;

const PromptList = styled.ul``;

const PromptItem = styled.li`
  padding: 8px 0;
  animation: ${fadeIn} 0.3s ease-in-out;
  animation-fill-mode: both;
  &:nth-child(n) {
    animation-delay: calc(0.1s * var(--item-index, 0));
  }
`;

const PromptText = styled.p`
  color: #666;
  margin: 0;
`;

const ContentWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  gap: 20px;
  width: 100%;
`;

const DialerSection = styled.div<{ isMobile: boolean }>`
  flex: 1;
  min-width: ${props => props.isMobile ? '100%' : '0'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TemplateSection = styled.div<{ isMobile: boolean }>`
  flex: ${props => props.isMobile ? '0 0 auto' : '1'};
  min-width: ${props => props.isMobile ? '100%' : '0'};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export {
  TemplateContainer,
  TitleContainer,
  Title,
  DescriptionContainer,
  Description,
  ComponentsContainer,
  ComponentItem,
  ComponentTitle,
  PromptList,
  PromptItem,
  PromptText,
  PageContainer,
  ContentWrapper,
  DialerSection,
  TemplateSection,
};
