import React, { useContext, useEffect, useState } from 'react'
import Counters from '../../components/counters'
import BarChartJamy from '../../components/shared/charts/bar'
import DashboardDataService from '../../services/dashboard'
import { Col, Form, Row, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import moment from 'moment'
import JamyContext from '../../context/jamyContext'

const DasboardContainer: React.FC = () => {
  const { user, org } = useContext(JamyContext)
  const dashboardDataService = new DashboardDataService()
  const [accumulated, setAccumulated] = useState({ duration: 0, meetings: 0 })
  const [currentPlanName, setCurrentPlanName] = useState({
    current_product_name: '',
    current_cost: 0
  })
  const [dataPrepare, setDataPrepare] = useState<any>([])
  const [users, setUsers] = useState<any>([])
  const [dataSets, setDataSets] = useState<any>([])
  const [labels, setLabels] = useState<Array<string>>([])
  const [filter] = useForm()
  const [dataCounters, setDataCounters] = useState<any>({
    counters: []
  })

  useEffect(() => {
    getLabelsByDate(moment().year().toString(), moment().format('MM'))
    getDatSets(
      filter.getFieldValue('year'),
      filter.getFieldValue('month'),
      user?.user_type === 'USER' ? user.id : undefined
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDataCounters({
      counters: [
        {
          counter: accumulated.duration ? accumulated.duration.toFixed(2) : 0,
          label: 'Minutes',
          color: '#5E6DB8'
        },
        {
          counter: accumulated.meetings,
          label: 'Reports',
          color: '#975DB8'
        },
        {
          counter: `$ ${currentPlanName.current_cost.toFixed(2)}`,
          label: `${currentPlanName.current_product_name.replaceAll(
            '-',
            ' '
          )} (USD)`,
          color: '#5333C1'
        }
      ]
    })
  }, [accumulated, currentPlanName])

  useEffect(() => {
    setDataSets([
      {
        data:
          filter.getFieldValue('type_chart') === 'Minutes'
            ? dataPrepare.minutes
            : dataPrepare.meetings,
        label: filter.getFieldValue('type_chart'),
        borderColor:
          filter.getFieldValue('type_chart') === 'Minutes'
            ? 'rgba(83, 51, 193, 1)'
            : 'rgba(151, 93, 184, 1)',
        backgroundColor:
          filter.getFieldValue('type_chart') === 'Minutes'
            ? 'rgba(83, 51, 193, 0.5)'
            : 'rgba(151, 93, 184, 0.5)'
      }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrepare])

  useEffect(() => {
    if (org) {
      let userList: any = []
      userList.push({
        value: undefined,
        label: 'All users'
      })
      org.users.forEach((element: any) => {
        userList.push({
          value: element.id,
          label: element.first_name + ' ' + element.last_name
        })
      })

      setUsers(userList)
    }
  }, [org])

  const optionsMonths = [
    {
      value: '01',
      label: 'January'
    },
    {
      value: '02',
      label: 'February'
    },
    {
      value: '03',
      label: 'March'
    },
    {
      value: '04',
      label: 'April'
    },
    {
      value: '05',
      label: 'May'
    },
    {
      value: '06',
      label: 'June'
    },
    {
      value: '07',
      label: 'July'
    },
    {
      value: '08',
      label: 'August'
    },
    {
      value: '09',
      label: 'September'
    },
    {
      value: '10',
      label: 'October'
    },
    {
      value: '11',
      label: 'November'
    },
    {
      value: '12',
      label: 'December'
    }
  ]

  const optionsCharts = [
    {
      value: 'Meetings',
      label: 'Reports'
    },
    {
      value: 'Minutes',
      label: 'Minutes'
    }
  ]

  const getLabelsByDate = (year: string, month: string) => {
    const daysInMonth = moment(`${year}-${month}-01`).daysInMonth()
    const labelsTemp: Array<string> = []
    for (let index = 1; index <= daysInMonth; index++) {
      const day = index < 10 ? `0${index}` : `${index}`
      const date = `${year}-${month}-${day}`
      labelsTemp.push(moment(date).format('DD-MMM'))
    }
    setLabels(labelsTemp)
  }

  const getDatSets = (year: string, month: string, userId?: string) => {
    let minutes: any = []
    let meetings: any = []
    dashboardDataService
      .getDataDashboard(year, month, userId)
      .then((response: any) => {
        setCurrentPlanName(response.data.current_plan)
        setAccumulated(response.data.accumulated)
        for (
          let index = 1;
          index < moment(`${year}-${month}-01`).daysInMonth();
          index++
        ) {
          minutes.push(response.data[index].duration)
          meetings.push(response.data[index].meetings)
        }

        setDataPrepare({ minutes: minutes, meetings: meetings })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const onFinish = (data: { year: string; month: string; user_id: string }) => {
    getLabelsByDate(
      data.year ? data.year : moment().year().toString(),
      data.month ? data.month : filter.getFieldValue('month')
    )
    getDatSets(
      data.year ? data.year : moment().year().toString(),
      data.month ? data.month : filter.getFieldValue('month'),
      data.user_id ? data.user_id : filter.getFieldValue('user_id')
    )
  }

  return (
    <div style={{ margin: '20px' }}>
      <div>
        <Form
          layout="vertical"
          form={filter}
          onFinish={onFinish}
          onValuesChange={onFinish}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6}>
              <Form.Item name="type_chart" initialValue={'Reports'}>
                <Select options={optionsCharts} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
              <Form.Item name="year" initialValue="2025">
                <Select
                  options={[
                    { value: '2023', label: '2023' },
                    { value: '2024', label: '2024' },
                    { value: '2025', label: '2025' }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
              <Form.Item name="month" initialValue={moment().format('MM')}>
                <Select options={optionsMonths} />
              </Form.Item>
            </Col>
            {user?.user_type === 'OWNER' && (
              <Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item name="user_id">
                  <Select
                    options={users}
                    placeholder="Select user"
                    allowClear
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </div>
      <br />
      <Counters counters={dataCounters.counters} />
      <br /> <br />
      <BarChartJamy labels={labels} datasets={dataSets} />
    </div>
  )
}

export default DasboardContainer
