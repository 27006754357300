import React from 'react';
import styled from 'styled-components';
import { PhoneFilled } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  gap: 10px;
  border: none;
  border-radius: 8px;
  background-color: #e0aa25;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #e0aa25;
  }
  
`;

const CallButton: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/call');
  };

  return (
    <StyledButton onClick={handleClick}>
      <Link to="/call" key={'call' + Math.random()}>
        <Tooltip title="Call" placement="right">
          <PhoneFilled style={{ color: '#fff', rotate: '90deg' }} />
        </Tooltip>
      </Link>
      <span style={{ fontSize: '10px', fontWeight: 'bold', color: '#fff'}}>
        Call
      </span>
    </StyledButton>
  );
};

export default CallButton; 