import React, { useEffect } from 'react'
import { useState } from 'react'
import { Popover, Button, Space } from 'antd'
import { StyledComponentEmojiPopoverReplay } from './styledComponents'
import emoji from './../../assets/icons/emoji.png'
import CommentsDataService from '../../services/comments'
import { AxiosError } from 'axios'

const emojiList = ['👍', '❤️', '😂', '😮', '😢', '🔥']

interface Reactions {
  [key: string]: number
}
type PropsEmojiPopover = {
  item: Comments.Comment
  getComments: () => void
}
const EmojiPopover: React.FC<PropsEmojiPopover> = ({ item, getComments }) => {
  const [selectedReactions, setSelectedReactions] = useState<Reactions>({})
  const [reactions, setReactions] = useState<any[]>([])
  const commentsDataService = new CommentsDataService()

  const handleReaction = (emoji: any) => {
    setSelectedReactions((prev) => ({
      ...prev,
      [emoji]: (prev[emoji] || 0) + 1
    }))
  }

  const emojiPopover = (
    <Space>
      {emojiList.map((emoji) => (
        <Button key={emoji} onClick={() => handleReaction(emoji)}>
          {emoji}
        </Button>
      ))}
    </Space>
  )

  useEffect(() => {
    if (Object.keys(selectedReactions).length > 0) {
      let mergedReactions = {}
      if (item.emoji_reaction) {
        mergedReactions = item.emoji_reaction.reduce<Record<string, number>>(
          (acc, obj) => {
            const [emoji, count] = Object.entries(obj)[0] as [string, number] // Explicita el tipo de la entrada
            acc[emoji] = (acc[emoji] || 0) + count // Suma los valores correctamente
            return acc
          },
          {} as Record<string, number>
        )
      }

      const arr: any[] = Object.entries(
        Object.assign({}, mergedReactions, selectedReactions)
      ).map(([key, value]) => ({
        [key]: value
      }))
      commentsDataService
        .updateComment({
          ...item,
          emoji_reaction: arr
        })
        .then(() => {
          getComments()
        })
        .catch((error: AxiosError) => {
          console.error(error)
        })
    }
  }, [selectedReactions])

  useEffect(() => {
    if (item.emoji_reaction && item.emoji_reaction?.length > 0) {
      setReactions(item.emoji_reaction)
    }
  }, [item])

  const reactionIcon = () => {
    return (
      <div style={{ display: 'flex' }}>
        {reactions.map((reaction, index) => {
          const entry = Object.entries(reaction)[0] as [string, number]
          if (!entry) return null // Previene posibles errores
          const [emoji, count] = entry
          return (
            <div key={index} style={{ fontSize: '15px', marginRight: '10px' }}>
              {emoji} <strong>{count}</strong>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Popover content={emojiPopover} trigger="click">
        <StyledComponentEmojiPopoverReplay src={emoji} />
      </Popover>
      {reactionIcon()}
    </div>
  )
}

export default EmojiPopover
