import styled from 'styled-components'
import { Layout } from 'antd'
const { Header: HeaderLayout } = Layout;

export const StyledHeaderContent = styled(HeaderLayout)`
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`
export const StyledHeaderLinkProfile = styled.div`
  color: #e0aa25;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  //xs
  @media all and (max-width: 576px) {
    font-size: 12px;
  }
`

export const StyledHeaderTitle = styled.span<{isLong?: boolean}>`
  font-size: 40px;
  font-weight: 800;
  color: #5333c1;

  //xs
  @media all and (max-width: 576px) {
    font-size: ${({ isLong }) => isLong ? '18px' : '28px'};
  }
`