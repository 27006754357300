import React, { useEffect, useState } from 'react'
import DayCalendar from '../../components/dayCalendar'
import {
  StyledMeetingCalendarSearcItem,
  StyledMeetingCalendarSearcItemDescription,
  StyledMeetingCalendarSearcItemName,
  StyledMeetingsCalendarContainerContent
} from './styledComponents'
import BannerCalendar from '../../components/bannerCalendar'
import moment from 'moment'
import MeetingsDataService from '../../services/meetings'
import { toast } from 'react-toastify'
import { filter } from 'lodash'
import Loading from '../../components/loading'
import { useNavigate } from 'react-router-dom'
import SearchBarAutoComplete from '../../components/searchBarAutocomplete'
import CalendarsDataService from '../../services/calendars'
import { Button } from 'antd'
import CreateManualMeetingForm from '../../components/createManualMeetingForm'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const MeetingsCalendarContainer: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [meetings, setMeetings] = useState<Meetings.Response | any>()
  const [options, setOptions] = useState<any>([])
  const [numberOfMonth, setNumberOfMonth] = useState(
    parseInt(moment().format('MM'), 10)
  )
  const [year, setYear] = useState(parseInt(moment().format('YYYY'), 10))
  const [isCreateManualMeetingFormOpen, setIsCreateManualMeetingFormOpen] =
    useState(false)
  const [createManualMeetingForm] = useForm()
  const month = moment(`${year}/${numberOfMonth}/01`).month('M').format('MMMM')
  const daysInMonth = moment(`${year}/${numberOfMonth}/01`)
    .month('M')
    .daysInMonth()
  const startDay = moment(`${year}/${numberOfMonth}/01`).startOf('month').day()
  const meetingService = new MeetingsDataService()
  const calendarDataService = new CalendarsDataService()

  useEffect(() => {
    getMeets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfMonth, year])

  const getMeets = () => {
    calendarDataService
      .getMeetingsByDate(year.toString(), numberOfMonth.toString())
      .then((response) => {
        setMeetings(response.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error.message, { theme: 'colored' })
        console.log(error)
      })
  }

  useEffect(() => {
    if (searchText !== '') {
      meetingService
        .getMeetingsSearch(searchText)
        .then((response) => {
          const op: any = []
          response.data.results.forEach((element, index) => {
            op.push({
              label: (
                <StyledMeetingCalendarSearcItem key={element.id + index}>
                  <StyledMeetingCalendarSearcItemName>
                    <strong>{element.name}</strong>
                    {element.start_time && (
                      <p
                        style={{
                          fontSize: '10px',
                          color: 'gray',
                          margin: '0'
                        }}
                      >
                        {moment(element.start_time).format(
                          'DD/MM/YYYY HH:mm A'
                        )}
                      </p>
                    )}
                  </StyledMeetingCalendarSearcItemName>
                  <StyledMeetingCalendarSearcItemDescription>
                    {element.summary?.substring(0, 100)}
                  </StyledMeetingCalendarSearcItemDescription>
                </StyledMeetingCalendarSearcItem>
              ),
              value: element.id
            })
          })
          setOptions(op)
        })
        .catch((e) => {
          console.log(e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const renderCalendar = () => {
    const days: any = []
    for (let index = 0; index < 42; index++) {
      const dayToRender = index - (startDay - 1)
      if (index >= startDay) {
        if (dayToRender <= daysInMonth) {
          days.push(
            <DayCalendar
              getMeets={getMeets}
              key={index}
              day={dayToRender}
              date={moment(`${year}/${numberOfMonth}/${dayToRender}`).format(
                'YYYY-MM-DD'
              )}
              dayName={moment(`${year}/${numberOfMonth}/${dayToRender}`).format(
                'ddd'
              )}
              meetings={getMeetingsByDate(
                moment(`${year}/${numberOfMonth}/${dayToRender}`).format(
                  'YYYY-MM-DD'
                )
              )}
            />
          )
        }
      } else {
        days.push(
          <DayCalendar
            getMeets={getMeets}
            key={index}
            meetings={[]}
            date={moment(`${year}/${numberOfMonth}/${dayToRender}`).format(
              'YYYY-MM-DD'
            )}
          />
        )
      }
    }
    return days
  }

  const getMeetingsByDate = (date: string) => {
    return filter(
      meetings,
      (v: Meetings.Results) =>
        moment(v.start_time).format('YYYY-MM-DD') === date
    )
  }

  const prev = () => {
    setLoading(true)
    if (numberOfMonth <= 1) {
      setYear(year - 1)
      setNumberOfMonth(12)
    } else {
      setNumberOfMonth(numberOfMonth - 1)
    }
  }

  const next = () => {
    setLoading(true)
    if (numberOfMonth >= 12) {
      setYear(year + 1)
      setNumberOfMonth(1)
    } else {
      setNumberOfMonth(numberOfMonth + 1)
    }
  }

  const handleSearch = (value: string) => {
    setOptions([])
    setSearchText(value)
  }

  const onSelect = (value: string) => {
    navigate(`/meetings/details/${value}`)
  }

  const onFinishCreateMeetingManual = (data: any) => {
    const payload = {
      invite_link: data['url-meeting'],
      meeting_name: data['meeting-name'],
      start_time: data['meeting-date'].format('YYYY-MM-DDTHH:mm:ss'),
      participants: data['participants'] || []
    }
    meetingService
      .createMeetingManual(payload)
      .then((data) => {
        toast.success(`Meeting have been created!`, { theme: 'colored' })
        createManualMeetingForm.setFieldValue('meeting-date', '')
        createManualMeetingForm.setFieldValue('url-meeting', '')
        createManualMeetingForm.setFieldValue('meeting-name', '')
        createManualMeetingForm.setFieldValue('participants', undefined)
        getMeets()
        console.info(data)
        setIsCreateManualMeetingFormOpen(false)
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.invite_link
        ) {
          toast.error(`${err.response.data.invite_link[0]}`, {
            theme: 'colored'
          })
        } else {
          toast.error(`Error: ${err}`, {
            theme: 'colored'
          })
        }
        console.error(err)
      })
  }

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Button
        type="primary"
        onClick={() => setIsCreateManualMeetingFormOpen(true)}
      >
        CREATE MEETING
      </Button>
      <SearchBarAutoComplete
        handleSearch={handleSearch}
        onSelect={onSelect}
        options={options}
      />
      <div style={{ display: 'flex', margin: '10px 0' }}>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            margin: '5px'
          }}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '5px',
              backgroundColor: '#3ba933'
            }}
          ></div>
          <p style={{ margin: '0 10px' }}>SENT</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            margin: '5px'
          }}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '5px',
              backgroundColor: '#975db8'
            }}
          ></div>
          <p style={{ margin: '0 10px' }}>PROCESSED</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            margin: '5px'
          }}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '5px',
              backgroundColor: '#007bcd'
            }}
          ></div>
          <p style={{ margin: '0 10px' }}>TO BE PROCESSED</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            margin: '5px'
          }}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '5px',
              backgroundColor: '#989b9d'
            }}
          ></div>
          <p style={{ margin: '0 10px' }}>NOT TO BE PROCESSED</p>
        </div>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            margin: '5px'
          }}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '5px',
              backgroundColor: 'black'
            }}
          ></div>
          <p style={{ margin: '0 10px' }}>PROCESSING</p>
        </div>
      </div>
      <BannerCalendar prev={prev} next={next} month={`${month} ${year}`} />
      <StyledMeetingsCalendarContainerContent>
        {meetings ? renderCalendar() : <div></div>}
      </StyledMeetingsCalendarContainerContent>
      <CreateManualMeetingForm
        isOpen={isCreateManualMeetingFormOpen}
        handleCancel={() => setIsCreateManualMeetingFormOpen(false)}
        onFinish={onFinishCreateMeetingManual}
        formData={createManualMeetingForm}
      />
    </div>
  )
}

export default MeetingsCalendarContainer
