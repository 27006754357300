import { http } from '../../config/http-common'

class CommentsDataService {
  createComment(data: Comments.Comment) {
    return http.post<Comments.CommentResponse>(`/comments/`, data)
  }

  updateComment(data: Comments.Comment) {
    return http.patch<Comments.CommentResponse>(`/comments/${data.id}/`, data)
  }

  getAllCommentsByMeetingId(meeting_id: string, section: string, item_id: string) {
    return http.get<Comments.CommentResponse>(`/comments/?meeting=${meeting_id}&section=${section}&item_id=${item_id}`)
  }
}

export default CommentsDataService
