import { LeftOutlined } from "@ant-design/icons";
import styled from "styled-components";

const DialerContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid #5333C1;
  border-radius: 20px;
  background-color: rgba(83, 51, 193, 0.1);
  ${props => props.isMobile && `
    width: 100%;
  `}
`;

const CallStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 5px;
`;

const PhoneInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

const TimerText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: gray;
`;

const CallStatusText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: gray;
  margin-bottom: 5px;
`;

const PhoneDisplay = styled.input<{ isMobile: boolean, isCollapsed: boolean }>`
  width: 100%;
  padding: 15px;
  max-height: ${props => props.isMobile ? (props.isCollapsed ? '52px' : '64px') : '64px'};
  min-height: ${props => props.isMobile ? (props.isCollapsed ? '52px' : '64px') : '64px'};
  font-size: ${props => props.isMobile ? (props.isCollapsed ? '12px' : '9px') : '20px'};
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  
  &:focus {
    border-color: #E0AA25;
  }
  
  &::placeholder {
    color: #999;
  }
`;

const DialPad = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  justify-items: center;
  align-items: center;
`;

const DialButton = styled.button<{ isMobile: boolean, isCollapsed: boolean }>`
  width: ${props => props.isMobile ? (props.isCollapsed ? '60px' : '2.2em') : '60px'};
  height: ${props => props.isMobile ? (props.isCollapsed ? '60px' : '2.2em') : '60px'};
  font-size: ${props => props.isMobile ? (props.isCollapsed ? '20px' : '16px') : '20px'};
  border-radius: 50%;
  border: 1px solid #ddd;
  background: white;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  &:hover {
    background: #f0f0f0;
  }
`;

const RecordingIndicator = styled.div`
  font-size: 14px;
  color: red;
  margin-left: 8px;
  font-weight: bold;
`;

const CallButton = styled.button<{ disabled?: boolean; isActive?: boolean }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  background: ${(props) =>
    props.disabled ? "#ccc" : props.isActive ? "#FF3B30" : "#4CD964"};
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: white;
  transition: background 0.2s ease-in-out;
  
  &:hover {
    background: ${(props) =>
      props.disabled ? "#ccc" : props.isActive ? "#FF6257" : "#43C45F"};
  }
`;

const CallButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  width: 100%;
`;

const BackButtonIcon = styled(LeftOutlined)`
  font-size: 24px;
  cursor: pointer;
`;

export { 
  DialerContainer,
  PhoneDisplay,
  DialPad,
  DialButton,
  CallButton,
  RecordingIndicator,
  CallButtonContainer,
  BackButtonIcon,
  CallStatusText,
  CallStatusContainer,
  TimerText,
  PhoneInputWrapper
};
