import styled from 'styled-components'

export const StyledComponentCommentItemContent = styled.div`
  padding: 10px 20px;
  background-color: #f0f7ff;
  border-radius: 10px;
  margin: 10px 0;
`
export const StyledComponentCommentItemAuthorContent = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
`
export const StyledComponentCommentItemAuthor = styled.p`
  color: '#5333C1';
  font-weight: bold;
  margin: 0 5px 0 0;
`
export const StyledComponentCommentItemDateTime = styled.p`
  color: lightgray;
  font-size: 11px;
  margin: 0;
  font-weight: 200;
`
export const StyledComponentCommentItemImgCircle = styled.div``
export const StyledComponentCommentActionsContent = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  width: 100%;
`
export const StyledComponentCommentActionsReplay = styled.p`
  color: gray;
  font-size: 14px;
  margin: 0 5px 0 0;
  cursor: pointer;
`
export const StyledComponentCommentActionsReact = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
`
