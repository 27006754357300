import React, { useContext, useEffect, useState } from 'react'
import JamyContext from '../../context/jamyContext'
import { useForm } from 'antd/es/form/Form'
import Loading from '../../components/loading'
import OrgsDataService from '../../services/org'
import { toast } from 'react-toastify'
import { Button, Col, Form, Input, Row } from 'antd'
import { StyledH2 } from '../../styles/styledComponents'
import MeetingsDataService from '../../services/meetings'
import { useParams } from 'react-router-dom'

const CustomWordsContainer: React.FC = () => {
  const { org, getOrgInformation } = useContext(JamyContext)
  const [loading, setLoading] = useState(false)
  const { meeting_id } = useParams()
  const [form] = useForm()
  const orgServiceData = new OrgsDataService()
  const meetingServiceData = new MeetingsDataService()

  const onFinishAdd = (data: any) => {
    setLoading(true)
    const wordsData: Orgs.CustomWords[] = org ? [...org.custom_words] : []
    const wordExists = wordsData.find((word) => word.word === data.word)
    if (wordExists) {
      wordExists.incorrects.push(data.incorrect)
    } else {
      wordsData.push({
        word: data.word,
        incorrects: [data.incorrect]
      })
    }
    if (meeting_id) {
      proccesMeetingAfterAddWords(meeting_id, wordsData)
    }
    orgServiceData
      .updateCustomWords(wordsData, org ? org.id : '')
      .then(() => {
        setLoading(false)
        getOrgInformation()
        form.resetFields()
        meetingServiceData.getMeetingById(meeting_id ? meeting_id : '')
        toast.success('Custom word is added', { theme: 'colored', toastId: 'success-add-word' })
      })
      .catch(() => {
        setLoading(false)
        toast.error('Error, try again', { theme: 'colored', toastId: 'error-add-word' })
      })
  }

  const proccesMeetingAfterAddWords = async (
    meeting_id: string,
    custom_words: Orgs.CustomWords[]
  ) => {
    const data = {
      meeting_id: meeting_id,
      custom_words: custom_words
    }
    await meetingServiceData.processMeeting(data)
  }

  return (
    <React.Fragment>
      <div style={{ maxWidth: '700px', padding: '20px' }}>
        <StyledH2>Add your organization's custom words</StyledH2>
        <p>
          Train Jamy with the custom words your organization uses so correct
          spelling is always guaranteed and is automatically corrected in this
          report.
        </p>
      </div>
      <Form layout="horizontal" form={form} onFinish={onFinishAdd}>
        <Row gutter={20} justify="center">
          <Col span={8}>
            <Form.Item name="incorrect">
              <Input placeholder="Incorrect Word" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="word">
              <Input placeholder="Correct Word" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button type="primary" htmlType="submit">
              {loading ? <Loading /> : 'Add'}
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default CustomWordsContainer
