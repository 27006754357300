import { http } from '../../config/http-common'

class PrincingDataService {
  getCustomPricingTable() {
    return http.get<Princing.Table[]>('/pricing/table/')
  }

  getPricingPlan() {
    return http.get<Princing.Plan>(`/pricing/plan/`)
  }

  updatePricingPlan(data: { new_price_id: string }) {
    return http.patch<Princing.Plan>(`/pricing/plan/`, data)
  }

  getUpgradeOverview(new_product_id: string, new_price_id: string) {
    return http.get<Princing.UpgradeOverview>(
      `/pricing/plan/update-overview/?new_product_id=${new_product_id}&new_price_id=${new_price_id}`
    )
  }

  getUpgradeNotification() {
    return http.get<Princing.NotificationUpgrade>(`/notifications/upgrade/`)
  }

  getCheckout(price_id: string, checkout_session_type?: string) {
    const payload: { price_id: string; checkout_session_type?: string } = { price_id }

    if (checkout_session_type) {
      payload.checkout_session_type = checkout_session_type
    }

    return http.post<Princing.Checkout>(`/checkout/`, payload)
  }

}

export default PrincingDataService
