import styled from 'styled-components'
import { Layout } from 'antd'
const { Sider: SiderLayout, Content: ContentLayout } = Layout;

export const Container = styled(Layout)`
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: #F9F9F9;
`
export const ContainerContent = styled(Layout)`
  width: 100%;
  padding: 0 2%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const Sider = styled(SiderLayout)`
  background-color: #fff;
  
`
export const Content = styled(ContentLayout)``

export const StyledContent = styled(ContentLayout)`
  background-color: #F9F9F9;
  padding: 2%;
  border-radius: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  min-height: 0;
  margin-bottom: 20px;
`

export const MenuIconContainer = styled.div<{ isCollapsed: boolean }>`
  top: 45px;
  right: ${props => props.isCollapsed ? '-30px' : '-10px'};
  position: absolute;
  z-index: 100;
  background-color: rgba(83, 51, 193, 0.1);
  border-radius: 0 10px 10px 0;
  padding: 8px;
  cursor: pointer;
`

