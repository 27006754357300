import { AutoComplete, Button, Table, TableColumnsType } from 'antd'
import {
  StyledContent,
  StyledAutocompleteWrapper,
  StyledTableWrapper
} from './styledComponents'
import { ArrowLeftOutlined } from '@ant-design/icons'

type Props = {
  onSelect: (value: string, option: any) => void
  handleSearch: (value: string) => void
  options: Meetings.Results[] | []
  optionSelected: undefined | object
  loadingSearch: boolean
  handleClickCreateTestButton: () => void
  searchText: string
  notFoundContent: boolean
  data: any[]
  columns: TableColumnsType<any>
  goBackButton: () => void
  componentName: string
}

const TestsComponents: React.FC<Props> = ({
  onSelect,
  handleSearch,
  options,
  optionSelected,
  loadingSearch,
  handleClickCreateTestButton,
  searchText,
  notFoundContent,
  data,
  columns,
  goBackButton,
  componentName
}) => {
  return (
    <div>
      <div
        style={{
          color: '#975DB8',
          fontWeight: '800',
          fontSize: '25px',
          lineHeight: '34.1px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={goBackButton}
      >
        <ArrowLeftOutlined
          style={{ fontSize: '1rem', marginRight: '.75rem' }}
        />
        {componentName}
      </div>
      <StyledContent>
        <StyledAutocompleteWrapper>
          <AutoComplete
            style={{ width: '100%' }}
            options={options}
            onSelect={onSelect}
            onSearch={handleSearch}
            placeholder="Pick a report to test this component with"
            value={searchText}
            notFoundContent={
              notFoundContent ? <div>Not match found!</div> : undefined
            }
          />
          <Button
            type="primary"
            disabled={optionSelected === undefined}
            loading={loadingSearch}
            onClick={handleClickCreateTestButton}
          >
            CREATE TEST
          </Button>
        </StyledAutocompleteWrapper>
        <StyledTableWrapper>
          <Table
            dataSource={data}
            columns={columns}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (data: any) => {
                if (
                  data.record.meeting_component.component.component_type ===
                  'GENERATION'
                ) {
                  return (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        columnGap: '1.25rem'
                      }}
                    >
                      <div>
                        <h3
                          style={{
                            color: '#975DB8',
                            fontSize: '15px',
                            lineHeight: '20.46px',
                            fontWeight: '800',
                            marginBottom: '1rem'
                          }}
                        >
                          PROMPT
                        </h3>
                        <div>
                          <p
                            style={{
                              lineHeight: '20.46px',
                              fontWeight: '400',
                              fontSize: '15px',
                              whiteSpace: 'break-spaces'
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                data.record.meeting_component.component.prompt
                                  .prompt[0].name
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <h3
                          style={{
                            color: '#975DB8',
                            fontSize: '15px',
                            lineHeight: '20.46px',
                            fontWeight: '800',
                            marginBottom: '1rem'
                          }}
                        >
                          RESULT
                        </h3>
                        <div>
                          <p
                            style={{
                              lineHeight: '20.46px',
                              fontWeight: '400',
                              fontSize: '15px',
                              whiteSpace: 'break-spaces'
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                data.record.meeting_component.result.result[0]
                                  .value
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
                return (
                  <div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '50% 50%',
                        columnGap: '1.25rem'
                      }}
                    >
                      <h3
                        style={{
                          color: '#975DB8',
                          fontSize: '15px',
                          lineHeight: '20.46px',
                          fontWeight: '800',
                          marginBottom: '1rem'
                        }}
                      >
                        PROMPT
                      </h3>
                      <h3
                        style={{
                          color: '#975DB8',
                          fontSize: '15px',
                          lineHeight: '20.46px',
                          fontWeight: '800',
                          marginBottom: '1rem'
                        }}
                      >
                        RESULT
                      </h3>
                    </div>

                    <div>
                      {data.record.meeting_component.component.prompt.prompt.map(
                        (record: any, index: any) => (
                          <div
                            key={index}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '50% 50%',
                              columnGap: '1.25rem',
                              borderBottom: '1px solid #E4E4E4',
                              padding: '0.75rem 0'
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  lineHeight: '20.46px',
                                  fontWeight: '400',
                                  fontSize: '15px',
                                  whiteSpace: 'break-spaces',
                                  marginBottom: '0.5rem'
                                }}
                              >
                                <span style={{ fontWeight: 800 }}>Name: </span>
                                {record.name}
                              </p>
                              <p
                                style={{
                                  lineHeight: '20.46px',
                                  fontWeight: '400',
                                  fontSize: '15px',
                                  whiteSpace: 'break-spaces',
                                  marginBottom: '0.5rem'
                                }}
                              >
                                <span style={{ fontWeight: 800 }}>Type: </span>
                                {record.type}
                              </p>
                              <p
                                style={{
                                  lineHeight: '20.46px',
                                  fontWeight: '400',
                                  fontSize: '15px',
                                  whiteSpace: 'break-spaces',
                                  marginBottom: '0.5rem'
                                }}
                              >
                                <span style={{ fontWeight: 800 }}>
                                  Description:{' '}
                                </span>
                                {record.description}
                              </p>
                            </div>
                            {data.record.meeting_component.result.result[index]
                              .value && (
                              <div>
                                <p
                                  style={{
                                    lineHeight: '20.46px',
                                    fontWeight: '400',
                                    fontSize: '15px',
                                    whiteSpace: 'break-spaces'
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      data.record.meeting_component.result
                                        .result[index].value
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )
              },
              rowExpandable: (record) => {
                return record.status === 'done';
              },
              showExpandColumn: false
            }}
            pagination={false}
          />
        </StyledTableWrapper>
      </StyledContent>
    </div>
  )
}

export default TestsComponents
