import React, { useState, useEffect } from 'react'
import { StyledH2V2 } from '../../../styles/styledComponents'
import ItemForms from '../../itemForms'
import editIcon from '../../../assets/icons/edit.svg'
import deleteImg from '../../../assets/icons/delete.svg'
import { Button, Form, Modal } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { toast } from 'react-toastify'
import MeetingsDataService from '../../../services/meetings'
import { useParams } from 'react-router-dom'
import SkeletonForm from './skeletonFormComponent'

type PropsForms = {
  name: string
  formsTemplateItem: any[]
  id?: string
  url: string
  status?: string
  getMeetingDetail: (meetingId: string) => void
}

const Forms: React.FC<PropsForms> = ({ formsTemplateItem, name, id, url, status, getMeetingDetail }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [formEdit] = useForm()
  const meetingService = new MeetingsDataService()
  const [removeFormModal, setRemoveFormModal] = useState(false)
  const [loadingRemoveForm, setLoadingRemoveForm] = useState(false)
  const [componentIdToRemove, setComponentIdToRemove] = useState<string | null>(null)
  const { meeting_id } = useParams()

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    
    if (status === 'processing' && meeting_id) {
      intervalId = setInterval(() => {
        getMeetingDetail(meeting_id);
      }, 10000); // Verificar cada 10 segundos
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [status, meeting_id]);

  const onFinish = (data: any) => {
    let result: any[] = []
    const newValues = Object.values(data)
    if (formsTemplateItem && formsTemplateItem.length) {
      result = formsTemplateItem.map((val, index) => ({
        name: val.name,
        value: newValues[index]
      }))
    }
    meetingService
      .updateMeetingComponentForm(id ? id : '', result)
      .then((data) => {
        toast.success(`Forms have been updated`, { theme: 'colored' })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
        setIsEditing(false)
      })
      .catch((error) => {
        toast.error(`Forms haven't been updated correctly`, {
          theme: 'colored'
        })
        console.log(error)
      })
  }
  
  const handleRemoveForm = () => {
    setComponentIdToRemove(id || null)
    setRemoveFormModal(true)
  }

  const onRemoveFromFolder = async (componentId: string | null) => {
    const meetingService = new MeetingsDataService()
    setLoadingRemoveForm(true)
    const report_ids = []
    if (componentId && meeting_id) {
      report_ids.push(componentId)
      try {
        await meetingService.updateMeeting({ report_ids }, meeting_id)
        toast.success(`Meeting component removed from meeting`, { theme: 'colored', toastId: 'remove-from-folder' })
        setRemoveFormModal(false)
        getMeetingDetail(meeting_id)
      } catch (error) {
        toast.error(`Meeting component not removed from meeting`, { theme: 'colored', toastId: 'remove-from-folder' })
      } finally {
        setLoadingRemoveForm(false)
      }
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'column'
      }}
    >
      <StyledH2V2>
        {name}{' '}
        {status === 'done' && (
          <span style={{ cursor: 'pointer' }} onClick={() => setIsEditing(true)}>
            <img
              src={editIcon}
              alt="edit"
              style={{
                margin: '0 10px',
                width: '16px',
                height: '16px'
              }}
            />
            <img
              src={deleteImg}
              alt="delete"
              style={{ cursor: 'pointer', margin: '0 10px' }}
              onClick={handleRemoveForm}
            />
          </span>
        )}
      </StyledH2V2>
      {status === 'processing' ? (
        <SkeletonForm />
      ) : (
        <Form
          form={formEdit}
          style={{ width: '100%' }}
          onFinish={onFinish}
          autoComplete="off"
        >
        {formsTemplateItem &&
          formsTemplateItem.length > 0 &&
          formsTemplateItem.map((item, index) => {
            return (
              <ItemForms
                result={item}
                key={index}
                index={index + 1}
                isEditing={isEditing}
                form={formEdit}
                url={url}
              />
            )
          })}

        {isEditing && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '1rem 0'
            }}
          >
            <Button
              type="text"
              htmlType="button"
              style={{
                marginRight: '10px',
                border: '1px solid #E0AA25',
                color: '#E0AA25',
                width: '8%'
              }}
              onClick={() => setIsEditing(false)}
              variant="outlined"
            >
              CANCEL
            </Button>
            <Button type="primary" htmlType="submit" style={{ width: '8%' }}>
              SAVE
            </Button>
          </div>
        )}
      </Form>
      )}
      <Modal
        open={removeFormModal}
        onCancel={() => setRemoveFormModal(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setRemoveFormModal(false)}
            disabled={loadingRemoveForm}
          >
            Cancel
          </Button>,
          <Button
            key="remove"
            type="primary"
            onClick={() => onRemoveFromFolder(componentIdToRemove)}
            disabled={loadingRemoveForm}
          >
            {loadingRemoveForm ? 'Removing...' : 'Remove'}
          </Button>
        ]}
        title="Remove meeting component from meeting"
        width={600}
      >
        <p>Are you sure you want to remove this meeting component from the meeting?</p>
      </Modal>
    </div>
  )
}

export default Forms
