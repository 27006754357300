import React from 'react'
import {
  StyledPricingTableCard,
  StyledPricingTableCardSelected,
  StyledPricingTableFeature,
  StyledPricingTableFeaturesList,
  StyledPricingTableFeaturesListTitle,
  StyledPricingTablePrice,
  StyledPricingTablePriceCompare,
  StyledPricingTablePriceDescription,
  StyledPricingTableTitle,
  StyledPricingTableUpgradeButton
} from './styledComponents'
import PricingDataService from '../../services/pricing'

type PropsPricingTable = {
  item: Princing.Table
  pricingPlanSelected: Princing.Plan | undefined
  recurring: string
  onClickUpgrade: (productName: string, type: string, priceID: string) => void
  activeAppSumoTier: number | null
}

const PricingTable: React.FC<PropsPricingTable> = ({
  item,
  pricingPlanSelected,
  recurring,
  onClickUpgrade,
  activeAppSumoTier
}) => {
  const URL_BILLING = process.env.REACT_APP_BILLING_STRIPE
    ? process.env.REACT_APP_BILLING_STRIPE
    : ''
  // **Determine if this plan should be highlighted as "Current Plan"**
  const isCurrentPlan =
    pricingPlanSelected?.price_id === item.prices[0]?.id || // Stripe Plan Match
    item.appsumo_license_active // AppSumo Match

  // **Determine the Button Text**
  let buttonText = 'Get Started'
  let buttonType: 'upgrade' | 'downgrade' | 'manage' | 'default' = 'default'
  let isAppSumoPlan = !!item.appsumo_license_tier
  
  if (isCurrentPlan) {
    buttonText = 'Manage'
    buttonType = 'manage'
  } else if (item.appsumo_license_tier && activeAppSumoTier) {
    // This is an AppSumo plan and we have an active tier to compare against
    if (item.appsumo_license_tier < activeAppSumoTier) {
      buttonText = 'Downgrade'
      buttonType = 'downgrade'
    } else if (item.appsumo_license_tier > activeAppSumoTier) {
      buttonText = 'Upgrade'
      buttonType = 'upgrade'
    }
  } else if (pricingPlanSelected?.price_id) {
    // Regular Stripe customer
    const planOrder = ['Pay-per-minute', 'Starter', 'Pro', 'Premium']
    const currentPlanIndex = planOrder.indexOf(
      pricingPlanSelected?.product_name || ''
    )
    const targetPlanIndex = planOrder.indexOf(item.name)

    if (currentPlanIndex !== -1 && targetPlanIndex !== -1) {
      if (targetPlanIndex < currentPlanIndex) {
        buttonText = 'Downgrade'
        buttonType = 'downgrade'
      } else if (targetPlanIndex > currentPlanIndex) {
        buttonText = 'Upgrade'
        buttonType = 'upgrade'
      } else {
        buttonText = 'Manage'
        buttonType = 'manage'
      }
    }
  }

  // Handle button click based on plan type
  const handleButtonClick = async () => {
    if (buttonText === 'Manage') {
      window.open(URL_BILLING)
    } else if (isAppSumoPlan) {
      // Redirect to AppSumo account products page
      window.open('https://appsumo.com/account/products/', '_blank')
    } else if (buttonText === 'Get Started') {
      try {
        // Use the pricing service to get checkout URL
        const pricingService = new PricingDataService();
        const response = await pricingService.getCheckout(item.prices[0].id);
        
        // Redirect to the checkout URL in a new tab
        if (response.data && response.data.checkout_session_url) {
          window.open(response.data.checkout_session_url, "_blank");
        }
      } catch (error) {
        console.error("Error getting checkout URL:", error);
      }
    } else {
      // Use the regular upgrade/downgrade flow for non-AppSumo plans
      onClickUpgrade(
        item.id,
        item ? 'upgrade' : 'subscribe',
        item.prices[0].id
      )
    }
  }

  return (
    <React.Fragment>
      <StyledPricingTableCard
        isSelected={isCurrentPlan}
        isMostPopular={item.name === 'Pro'}
      >
        <StyledPricingTableCardSelected
          hidden={!(item.name === 'Pro' && !pricingPlanSelected)}
        >
          MOST POPULAR
        </StyledPricingTableCardSelected>
        <StyledPricingTableCardSelected hidden={!isCurrentPlan}>
          Current Plan
        </StyledPricingTableCardSelected>

        <StyledPricingTableTitle>{item.name}</StyledPricingTableTitle>
        <p style={{ height: '100px', padding: '10px', fontSize: '13px' }}>
          {item.description?.split('Visit')[0] ?? ''}
        </p>

        <div
          style={{
            height: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledPricingTablePriceCompare hidden={recurring === 'Monthly'}>
              $
              {(item.prices.find(
                (x) =>
                  x.recurring.interval ===
                  (recurring === 'Yearly' ? 'month' : 'year')
              )?.unit_amount ?? 0) / 100}
            </StyledPricingTablePriceCompare>
            <StyledPricingTablePrice>
              ${' '}
              {parseFloat(item.default_price_converted ?? '0') ||
                (item.prices.find((x) => x.recurring.interval === 'lifetime')
                  ?.unit_amount ??
                  0)}
            </StyledPricingTablePrice>
          </div>

          <StyledPricingTablePriceDescription>
            {item.name === 'Pay-per-minute'
              ? 'Billed monthly based on usage'
              : item.prices.some((x) => x.recurring.interval === 'lifetime')
              ? 'Lifetime access, one-time payment'
              : recurring === 'Monthly'
              ? 'Per seat, per month'
              : 'Per seat, per month, billed annually'}
          </StyledPricingTablePriceDescription>
        </div>

        <StyledPricingTableUpgradeButton
          buttonType={buttonType}
          onClick={handleButtonClick}
        >
          {buttonText}
        </StyledPricingTableUpgradeButton>

        <StyledPricingTableFeaturesListTitle style={{ marginTop: '60px' }}>
          This includes:
        </StyledPricingTableFeaturesListTitle>
        <StyledPricingTableFeaturesList>
          {item.features.map((feature, index) => (
            <StyledPricingTableFeature key={index}>
              {feature.name}
            </StyledPricingTableFeature>
          ))}
        </StyledPricingTableFeaturesList>
      </StyledPricingTableCard>
    </React.Fragment>
  )
}

export default PricingTable
