import styled from 'styled-components'
import { Layout } from 'antd'
const { Header: HeaderLayout } = Layout;

export const StyledHeaderContent = styled(HeaderLayout)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #F9F9F9;
`

export const StyledHeaderButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
`

