import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import {
  StyledLink,
  StyledTemplateContent,
  StyledTemplatePromptItem,
  StyledTemplatePromptItemContent,
  StyledTemplatePromptItemDeleteImg,
  StyledTemplatePromptItemNumber,
  StyledTemplatePromptItemText
} from './styledComponents'
import { StyledH2 } from '../../styles/styledComponents'
import {
  Button,
  Form,
  FormInstance,
  Input,
  Select,
  Switch,
  Tooltip
} from 'antd'
import deleteImg from '../../assets/icons/delete.svg'
import { useNavigate } from 'react-router-dom'
import { Tabs, TabsProps } from 'antd'
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons'
import JamyContext from '../../context/jamyContext'

type PropsTemplate = {
  form: FormInstance
  templateEdit: any
  componentsDefault: Components.ResultComponent[]
  components: Components.ResultComponent[]
  onFinish: (data: any) => void
  setComponents: Dispatch<SetStateAction<Components.ResultComponent[]>>
  setComponentsDefault: Dispatch<SetStateAction<Components.ResultComponent[]>>
  editors: any[]
}

const Template: React.FC<PropsTemplate> = ({
  form,
  templateEdit,
  componentsDefault,
  components,
  setComponents,
  setComponentsDefault,
  onFinish,
  editors
}) => {
  const { user } = useContext(JamyContext)
  const navigate = useNavigate()
  const filteredEditors = editors.length
    ? editors.filter((val) => val.id !== user?.id)
    : []
  let isTheUserAnEditor: undefined | boolean
  const onChange = (key: string) => {
    console.log(key)
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'My Components',
      children: (
        <div>
          {componentsDefault
            .filter(
              (x) =>
                x.owner.id === user?.id ||
                x.editors.some((editor) => editor.id === user?.id)
            )
            .map((component, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '10px 0'
                  }}
                >
                  <p style={{ margin: '0' }}>{component.name}</p>
                  <Button
                    htmlType="button"
                    type="primary"
                    onClick={() => {
                      setComponents([...components, component])
                      setComponentsDefault(
                        componentsDefault.filter((x) => x.id !== component.id)
                      )
                    }}
                  >
                    +
                  </Button>
                </div>
              )
            })}
        </div>
      )
    },
    {
      key: '2',
      label: 'Public',
      children: (
        <div>
          {componentsDefault
            .filter((x) => x.public === true)
            .map((component, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '10px 0'
                  }}
                >
                  <p style={{ margin: '0' }}>{component.name}</p>
                  <Button
                    htmlType="button"
                    type="primary"
                    onClick={() => {
                      setComponents([...components, component])
                      setComponentsDefault(
                        componentsDefault.filter((x) => x.id !== component.id)
                      )
                    }}
                  >
                    +
                  </Button>
                </div>
              )
            })}
        </div>
      )
    }
  ]

  if (templateEdit) {
    isTheUserAnEditor = templateEdit.editors.some(
      (el: any) => el.id === user?.id
    )
  }

  const handleOnFinish: (values: any) => void = (values) => {
    if (templateEdit === undefined && isTheUserAnEditor === undefined) {
      onFinish(values)
    }

    if (templateEdit && isTheUserAnEditor) {
      onFinish(values)
    }

    return undefined
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleOnFinish}>
      <StyledLink to={'/templates'}>
        <div
          style={{ display: 'flex', alignItems: 'center', color: '#5333c1' }}
        >
          <ArrowLeftOutlined
            style={{ fontSize: '1.25rem', marginRight: '.25rem' }}
          />{' '}
          <span>Back</span>
        </div>
      </StyledLink>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '30%' }}>
          <StyledTemplateContent>
            <StyledH2>GENERAL INFORMATION</StyledH2>
            <div>
              <Form.Item name={'id'} hidden>
                <Input></Input>
              </Form.Item>
              <Form.Item name={'name'}>
                <Input placeholder="Name"></Input>
              </Form.Item>
              <div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                  className="template-description"
                >
                  <Form.Item
                    name={'description'}
                    style={{
                      flexGrow: 1,
                      marginBottom: 0
                    }}
                  >
                    <Input.TextArea
                      placeholder={`Description of the Template.

Ex: Customer interview template to understand biggest pain points, potential solutions and asks. Also to get feedback on specific features and understand features that add the most value. This template should be used for calls with customers that explore the product, its features, and customer feedback.`}
                      autoSize={{ minRows: 7, maxRows: 9 }}
                    />
                  </Form.Item>
                  <Tooltip
                    placement="right"
                    title={
                      <p>
                        It's <strong>important</strong> to add a good and
                        representative description of your Template because Jamy
                        will use that description to{' '}
                        <strong>automatically determine</strong> when this
                        Template should be applied to generate a Meeting Report.
                      </p>
                    }
                  >
                    <InfoCircleOutlined
                      style={{
                        color: 'black',
                        marginLeft: '10px',
                        cursor: 'pointer'
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
              <Form.Item name={'editors'}>
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select an editor"
                  optionFilterProp="label"
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={
                    filteredEditors.length
                      ? filteredEditors.map((val) => {
                          return {
                            value: val.id,
                            label: val.first_name + ' ' + val.last_name
                          }
                        })
                      : []
                  }
                />
              </Form.Item>
              <Form.Item name={'edit'} hidden={true}>
                <Switch />
              </Form.Item>
              {/* <Form.Item
                name={'public'}
                label="Make public"
                layout="horizontal"
              >
                <Switch />
              </Form.Item> */}
              <Form.Item
                name={'tasks_and_topics'}
                label="Tasks and Topics"
                layout="horizontal"
              >
                <Switch />
              </Form.Item>
            </div>
          </StyledTemplateContent>
          <StyledTemplateContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start'
              }}
              className="components-list"
            >
              <StyledH2>COMPONENTS LIST</StyledH2>
              <Tooltip
                title={
                  <p>
                    Add one or more Components to your Template. Choose from
                    pre-built Public Components or from the Components you've
                    created.
                  </p>
                }
              >
                <InfoCircleOutlined style={{ color: 'black' }} />
              </Tooltip>
            </div>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </StyledTemplateContent>
        </div>
        <div style={{ width: '70%' }}>
          <StyledTemplateContent>
            <StyledH2>CHOSEN COMPONENTS FOR YOUR TEMPLATE</StyledH2>
            <div>
              {components.map((item, index) => {
                return (
                  <StyledTemplatePromptItem key={index}>
                    <StyledTemplatePromptItemContent>
                      <StyledTemplatePromptItemNumber>
                        {index + 1}
                      </StyledTemplatePromptItemNumber>
                      <StyledTemplatePromptItemText>
                        {item.name}
                      </StyledTemplatePromptItemText>
                    </StyledTemplatePromptItemContent>
                    <StyledTemplatePromptItemDeleteImg
                      src={deleteImg}
                      onClick={() =>
                        setComponents(
                          components.filter((x) => x.id !== item.id)
                        )
                      }
                    />
                  </StyledTemplatePromptItem>
                )
              })}
            </div>
            <div
              style={{
                width: '100%',
                justifyContent: 'end',
                display: 'flex',
                marginTop: '20px'
              }}
            >
              <Button
                type="default"
                htmlType="button"
                style={{ marginRight: '10px' }}
                onClick={() => navigate('/templates')}
              >
                CANCEL
              </Button>
              <Tooltip
                title={
                  templateEdit &&
                  !isTheUserAnEditor &&
                  "You don't have permissions to edit this Template"
                }
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  hidden={templateEdit && !isTheUserAnEditor}
                >
                  {templateEdit ? 'UPDATE TEMPLATE' : 'CREATE TEMPLATE'}
                </Button>
              </Tooltip>
            </div>
          </StyledTemplateContent>
        </div>
      </div>
    </Form>
  )
}

export default Template
