import { useState, useEffect } from "react"
import { Select } from "antd"
import TemplatesDataService from "../../../../services/templates"

interface TemplateSelectProps {
  onChange: (value: string) => void
  showSearch: boolean
}

const TemplateSelect: React.FC<TemplateSelectProps> = ({ onChange }) => {
  const [templates, setTemplates] = useState<Templates.Results[]>()
  const templatesDataService = new TemplatesDataService()
  
  useEffect(() => {
    templatesDataService.getAllTemplates()
      .then((response) => {
        setTemplates(response.data?.results)
      })
  }, [])

  return (
    <Select
      placeholder="Select a template"
      onChange={(value) => onChange(value as string)}
      showSearch
      style={{ 
        width: '100%',
      }}
      
      allowClear
      filterOption={(input, option) =>
        (option?.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      onClear={() => onChange('')}
      dropdownStyle={{
        borderRadius: '10px',
        border: '1px solid #5333C1',
      }}
      
    >
      {templates?.map((template) => (
        <Select.Option key={template.id} value={template.id}>
          {template.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default TemplateSelect
