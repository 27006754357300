import { StyledMenuIcon } from './styledComponents'
import bots from '../../assets/menu/bots.png'
import dashboard from '../../assets/menu/dashboard.png'
import organization from '../../assets/menu/organization.png'
import users from '../../assets/menu/users.png'
import integration from '../../assets/menu/integration.png'
import meetings from '../../assets/menu/calendar.png'
import templates from '../../assets/menu/templates.png'
import folders from '../../assets/menu/folders.png'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { PhoneOutlined } from '@ant-design/icons'
import CallButton from '../callButton'

export const menuList = [
  {
    path: '/call',
    icon: (
      <CallButton />
    ),
    hidden: false,
    id: 'call'
  },
  {
    path: '/dashboard',
    icon: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Link to="/dashboard" key={'dasboard' + Math.random()}>
          <Tooltip title="Dashboard" placement="right">
            <StyledMenuIcon src={dashboard} />
          </Tooltip>
        </Link>
        <p style={{ fontSize: '10px', fontWeight: 'bold', color: '#5333C1' }}>
          Dashboard
        </p>
      </div>
    ),
    hidden: false,
    id: 'dashboard'
  },
  {
    path: '/organization',
    icon: (
      <Link to="/organization" key={'organization' + Math.random()}>
        <Tooltip title="Organization" placement="right">
          <StyledMenuIcon src={organization} />
        </Tooltip>
      </Link>
    ),
    hidden: true,
    id: 'organization'
  },
  {
    path: '/calendar',
    icon: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Link to="/calendar" key={'meetings' + Math.random()}>
          <Tooltip title="Meetings" placement="right">
            <StyledMenuIcon src={meetings} />
          </Tooltip>
        </Link>
        <p style={{ fontSize: '10px', fontWeight: 'bold', color: '#5333C1' }}>
          Reports
        </p>
      </div>
    ),
    hidden: false,
    id: 'calendar'
  },
  {
    path: '/folders',
    icon: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Link to="/folders" key={'folders' + Math.random()}>
          <Tooltip title="Folders" placement="right">
            <StyledMenuIcon src={folders} />
          </Tooltip>
        </Link>
        <p style={{ fontSize: '10px', fontWeight: 'bold', color: '#5333C1' }}>
          Folders
        </p>
      </div>
    ),
    hidden: false,
    id: 'folders'
  },
  {
    path: '/users',
    icon: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Link to="/users" key={'users' + Math.random()}>
          <Tooltip title="Users" placement="right">
            <StyledMenuIcon src={users} />
          </Tooltip>
        </Link>
        <p style={{ fontSize: '10px', fontWeight: 'bold', color: '#5333C1' }}>
          Users
        </p>
      </div>
    ),
    hidden: false,
    id: 'users'
  },
  {
    path: '/integrations',
    icon: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Link to="/integrations" key={'integrations' + Math.random()}>
          <Tooltip title="Integrations" placement="right">
            <StyledMenuIcon src={integration} />
          </Tooltip>
        </Link>
        <p style={{ fontSize: '10px', fontWeight: 'bold', color: '#5333C1' }}>
          Integrations
        </p>
      </div>
    ),
    hidden: false,
    id: 'integrations'
  },
  {
    path: '/bots',
    icon: (
      <Link to="/bots" key={'bots' + Math.random()}>
        <Tooltip title="Bots" placement="right">
          <StyledMenuIcon src={bots} />
        </Tooltip>
      </Link>
    ),
    hidden: true,
    id: 'bots'
  },
  {
    path: '/templates',
    icon: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Link to="/report-creator" key={'report-creator' + Math.random()}>
          <Tooltip title="Report Creator" placement="right">
            <StyledMenuIcon src={templates} />
          </Tooltip>
        </Link>
        <p style={{ fontSize: '9px', fontWeight: 'bold', color: '#5333C1' }}>
          Report Creator
        </p>
      </div>
    ),
    hidden: false,
    id: 'templates'
  }
]
