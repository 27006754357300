import React from 'react'
import {
  StyledStripeResultContainer,
  StyledStripeResultPlan,
  StyledStripeResultThanks
} from './styledComponents'
import { Button, Result } from 'antd'
import logo from './../../assets/logo/logo.png'
import { useNavigate } from 'react-router-dom'

const StripeResult: React.FC = () => {
  const navigate = useNavigate()
  return (
    <StyledStripeResultContainer>
      <StyledStripeResultThanks>
        <Result
          status="success"
          title="Thanks for subscribing"
          subTitle="A payment to Jamy Technologies, LLC will appear on your statement."
          extra={[
            <Button
              type="primary"
              htmlType="button"
              onClick={() => navigate('/welcome')}
            >
              Continue with setup
            </Button>
          ]}
        />
      </StyledStripeResultThanks>
    </StyledStripeResultContainer>
  )
}

export default StripeResult
