import React, { useEffect, useState } from 'react'
import CommentsDataService from '../../services/comments'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import CommentItem from '../../components/commentItem'
import CommentForm from '../../components/commentForm'

type PropsCommentsContainer = {
  item: any
}

const CommentsContainer: React.FC<PropsCommentsContainer> = ({ item }) => {
  const itemParsed: Comments.Item = item as Comments.Item
  const commentsDataService = new CommentsDataService()
  const { meeting_id } = useParams()
  const [comments, setComments] = useState<Comments.Comment[]>([])

  useEffect(() => {
    getComments()
  }, [])

  const getComments = () => {
    const section: string = itemParsed.topic ? 'topics' : 'tasks'
    commentsDataService
      .getAllCommentsByMeetingId(
        meeting_id ? meeting_id : '',
        section,
        itemParsed.id
      )
      .then((response) => {
        setComments(response.data.results)
      })
      .catch((error: AxiosError) => console.error(error))
  }

  return (
    <div style={{ marginTop: '50px', height: '500px' }}>
      <h1 style={{ fontWeight: 'bold' }}>Comments</h1>
      <div>
        {comments.map((item, index) => {
          return (
            <div>
              <CommentItem
                item={item}
                key={index}
                parent_comment={item.id}
                getComments={getComments}
              />
              {item.child_comments &&
                item.child_comments?.length > 0 &&
                item.child_comments.map((element, key) => {
                  return (
                    <div style={{ marginLeft: '50px' }}>
                      <CommentItem
                        key={key}
                        item={element}
                        parent_comment={item.id}
                        getComments={getComments}
                      />
                    </div>
                  )
                })}
            </div>
          )
        })}
      </div>
      <CommentForm itemParsed={itemParsed} getComments={getComments} />
    </div>
  )
}

export default CommentsContainer
