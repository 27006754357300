import styled from 'styled-components'
import { Link } from "react-router-dom"

interface ItemColorMeeting {
  color?: string
}

export const StyledDayCalendarContent = styled.div`
  width: 95%;
  height: 110px;
  background-color: white;
  border: 1px solid #f3efef;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
`
export const StyledDayText = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 8px 5px;
  cursor: pointer;
  text-decoration: none;

  p {
    font-size: 18px;
    color: rgb(83, 51, 193);
    font-weight: bold;
    margin: 0;
  }

  label {
    font-size: 10px;
    color: #a8a8a8;
    margin: 0;
  }
`
export const StyledMeetingItemContent = styled.div`
  margin-top: 5px;
  background-color: #f1f1f1;
  cursor: pointer;

  :hover {
    background-color: #d3d1d1;
  }
`

export const StyledMeetingItemContentLink = styled(Link)`
  margin-top: 5px;
  background-color: #f1f1f1;
  cursor: pointer;
  display: block;
  text-decoration: none;

  :hover {
    background-color: #d3d1d1;
  }
`

export const StyledMeetingItem = styled.div<ItemColorMeeting>`
  font-size: 10px;
  padding: 2px;
  margin: 0 5px;
  color: ${(props) => props.color};
  font-weight: bold;
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
export const StyledMeetingMoreItem = styled.p`
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 15px;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: white;
  cursor: pointer;
`
