import { Button, Form, Mentions } from 'antd'
import { useForm } from 'antd/es/form/Form'
import FormItem from 'antd/es/form/FormItem'
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import JamyContext from '../../context/jamyContext'
import UserDataService from '../../services/users'
import CommentsDataService from '../../services/comments'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'

type PropsCommentForm = {
  itemParsed: Comments.Item
  parent_comment?: string
  getComments: () => void
  setIsReplying?: Dispatch<SetStateAction<boolean>>
}

const CommentForm: React.FC<PropsCommentForm> = ({
  itemParsed,
  parent_comment,
  getComments,
  setIsReplying
}) => {
  const [formComments] = useForm()
  const { meeting_id } = useParams()
  const { user } = useContext(JamyContext)
  const usersDataService = new UserDataService()
  const commentsDataService = new CommentsDataService()
  const [users, setUsers] = useState<User.ICreateUserResponse[]>([])
  const [optionsMentions, setOptionsMentions] = useState<
    {
      value: string
      label: string
      email: string
    }[]
  >([])

  const handleSubmitComment = (data: { comment: string }) => {
    const dataRequest: Comments.Comment = {
      text: data.comment,
      author: user ? user.id : '',
      tagged_users: extractMentionsWithEmails(data.comment, optionsMentions),
      meeting: meeting_id ? meeting_id : '',
      section: itemParsed.topic ? 'topics' : 'tasks',
      item_id: itemParsed.id,
      parent_comment: parent_comment
    }
    commentsDataService
      .createComment(dataRequest)
      .then(() => {
        getComments()
        if (setIsReplying) {
          setIsReplying(false)
        }
        toast.success('Comment added succesfully', { theme: 'colored' })
        formComments.resetFields()
      })
      .catch((error: AxiosError) => {
        getComments()
        toast.error(error.message, { theme: 'colored' })
      })
  }

  const extractMentionsWithEmails = (text: any, users: any) => {
    const mentionRegex = /@([A-Za-zÀ-ÖØ-öø-ÿ]+(?:[A-Za-zÀ-ÖØ-öø-ÿ]+)?)/g
    const mentions = [...text.matchAll(mentionRegex)].map((match) =>
      match[1].trim()
    )
    return mentions
      .map((name) => users.find((u: any) => u.value === name)?.email)
      .filter((email) => email)
  }

  useEffect(() => {
    usersDataService
      .getAllUsers()
      .then((response) => {
        setUsers(response.data.results)
      })
      .catch((error: AxiosError) => console.error(error))
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      let mentionsOp: { label: string; value: string; email: string }[] = []
      users.forEach((element) => {
        mentionsOp.push({
          label: element.first_name.replace(' ', ''),
          value: element.first_name.replace(' ', ''),
          email: element.email
        })
      })
      setOptionsMentions(mentionsOp)
    }
  }, [users])

  return (
    <Form form={formComments} onFinish={handleSubmitComment}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '90%' }}>
          <FormItem name="comment">
            <Mentions
              autoSize
              placeholder="You can use @ to ref user here"
              options={optionsMentions}
            />
          </FormItem>
        </div>
        <div style={{ width: '10%', marginLeft: '5px' }}>
          <FormItem>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: '100%', height: '35px' }}
            >
              SEND
            </Button>
          </FormItem>
        </div>
      </div>
    </Form>
  )
}

export default CommentForm
