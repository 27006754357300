import React, { useContext, useEffect, useState } from 'react'
import {
  StyledCalendarConnectionBox,
  StyledCalendarConnectionContainer,
  StyledCalendarConnectionDescription,
  StyledCalendarConnectionTitle
} from './styledComponents'
import CalendarBtn from '../../components/shared/calendarBtn'
import googleCalendar from '../../assets/icons/googleCalendar.png'
import outlookCalendar from '../../assets/icons/outlookCalendar.png'
import CalendarConnected from '../../components/calendarConnected'
import { useForm } from 'antd/es/form/Form'
import { useLocation, useNavigate } from 'react-router-dom'
import JamyContext from '../../context/jamyContext'
import CalendarsDataService from '../../services/calendars'
import Loading from '../../components/loading'
import { optionsMeetings } from '../../components/calendarConnectedEdit/options'
import { toast } from 'react-toastify'
import UserDataService from '../../services/users'

const CalendarConnectionContainer: React.FC = () => {
  const { recall_calendar_auth_token, user, getUser } = useContext(JamyContext)
  const { pathname } = useLocation()
  const calendarDataService = new CalendarsDataService()
  const userDataService = new UserDataService()
  const [formEdit] = useForm()
  const [isLoading, setIsLoading] = useState(true)
  const [isEditGoogle, setIsEditGoogle] = useState(false)
  const [preferencesGoogle, setPreferencesGoogle] = useState<any>()
  const [googleCalendarConnected, setGoogleCalendarConnected] = useState({
    calendar: 'Google Calendar',
    status: false,
    icon: googleCalendar
  })

  const [msCalendarConnected, setMSCalendarConnected] = useState({
    calendar: 'Microsoft Outlook Calendar',
    status: false,
    icon: outlookCalendar
  })

  const onFinish = (data: { isEnable: boolean; join: string }) => {
    calendarDataService
      .updatePreferences({
        preferences: optionsMeetings.filter((x) => x.value === data.join)[0]
          .preferences
      })
      .then(() => {
        toast.success('Preferences updated', { theme: 'colored' })
      })
      .catch((error) => {
        console.error(error)
      })

    userDataService.updateUserMeetingsSent(
      { automatic_notification: !data.isEnable },
      user ? user.id : ''
    )
    getCalendarConfiguration()
    getUser()
    setIsEditGoogle(false)
  }

  const onClickGoogle = () => {
    const STATE = JSON.stringify({
      recall_calendar_auth_token: recall_calendar_auth_token,
      google_oauth_redirect_url:
        'https://api.jamy.ai/v1/google_oauth_callback/',
      success_url: `${process.env.REACT_APP_REDIRECT_URI}/welcome?isConnectedGoogle=true`,
      error_url: `${process.env.REACT_APP_REDIRECT_URI}/welcome`
    })
    const uri = `https://accounts.google.com/o/oauth2/v2/auth?scope=${process.env.REACT_APP_GOOGLE_APP_SCOPES}&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code&state=${STATE}&redirect_uri=https://api.jamy.ai/v1/google_oauth_callback/&client_id=${process.env.REACT_APP_GOOGLE_APP_CLIENT_ID}`

    window.location.replace(uri)
  }

  const onClicMS = () => {
    const STATE = JSON.stringify({
      recall_calendar_auth_token: recall_calendar_auth_token,
      ms_oauth_redirect_url: 'https://api.jamy.ai/v1/ms_oauth_callback/',
      success_url: `${process.env.REACT_APP_REDIRECT_URI}/welcome?isConnectedMS=true`,
      error_url: `${process.env.REACT_APP_REDIRECT_URI}/welcome`
    })
    const uri = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?scope=${process.env.REACT_APP_MS_APP_SCOPES}&response_mode=query&response_type=code&state=${STATE}&redirect_uri=https://api.jamy.ai/v1/ms_oauth_callback/&client_id=${process.env.REACT_APP_MS_APP_CLIENT_ID}`
    window.location.replace(uri)
  }

  useEffect(() => {
    getCalendarConfiguration()
  }, [])

  const getCalendarConfiguration = () => {
    calendarDataService
      .getCalendar()
      .then((response) => {
        if (response.data) {
          const isGoogleConnected = response.data.connections.filter(
            (x: any) => x.platform === 'google'
          )[0].connected

          const isMSConnected = response.data.connections.filter(
            (x: any) => x.platform === 'microsoft'
          )[0].connected

          const preferencesTemp = {
            record_non_host: response.data.preferences.record_non_host,
            record_recurring: response.data.preferences.record_recurring,
            record_external: response.data.preferences.record_external,
            record_internal: response.data.preferences.record_internal,
            record_confirmed: response.data.preferences.record_confirmed,
            record_only_host: response.data.preferences.record_only_host,
          }

          setPreferencesGoogle({...getValuePreferences(preferencesTemp), bot_name: `${user?.first_name || 'Jamy.ai'}'s Meeting Assistant`})
          setGoogleCalendarConnected({
            ...googleCalendarConnected,
            status: isGoogleConnected
          })
          setMSCalendarConnected({
            ...msCalendarConnected,
            status: isMSConnected
          })
          setIsLoading(false)
        }
      })
      .catch(() => {
        toast.error('Error, try again.', { theme: 'colored' })
      })
  }

  const getValuePreferences = (preferences: any) => {
    const preferenceValue = optionsMeetings.find(
      (x) => JSON.stringify(x.preferences) === JSON.stringify(preferences)
    )
    return {
      ...preferenceValue,
      preferences: {
        ...preferenceValue?.preferences,
        bot_name: `${user?.first_name || 'Jamy.ai'}'s Meeting Assistant`
      }
    }
  }

  const disconnectAcount = (platform: string) => {
    const id = localStorage.getItem('id')
    calendarDataService
      .disconectCalendar({ platform: platform })
      .then(() => {
        userDataService
          .updateUserCalendar(
            platform === 'google'
              ? { google_calendar_connection: false }
              : { outlook_calendar_connection: false },
            id ? id : ''
          )
          .then(() => {
            setIsLoading(true)
            getCalendarConfiguration()
            getUser()
            toast.success(`${platform} has been disconeected`, {
              theme: 'colored'
            })
          })
          .catch((error) => {
            toast.error(`Error, try again.`, { theme: 'colored' })
          })
      })
      .catch(() => {
        toast.error(`Error, try again.`, { theme: 'colored' })
      })
  }

  return isLoading ? (
    <div style={{ padding: '20px' }}>
      <p>Loading ...</p>
    </div>
  ) : (
    <StyledCalendarConnectionContainer>
      <div hidden={pathname === '/integrations' ? true : false}>
        <StyledCalendarConnectionTitle>
          Which calendar do you want to use with Jamy?
        </StyledCalendarConnectionTitle>
        <StyledCalendarConnectionDescription>
          We recommend selecting your busiest calendar, which is usually
          work-related
        </StyledCalendarConnectionDescription>
        <br />
        <p style={{ width: '70%' }}>
          By connecting your calendar to Jamy, we will be able to send a bot as
          a participant to your meetings so we can record them and generate a
          transcript and summary. You can also configure which meetings you want
          Jamy to join with a couple of clicks
        </p>
      </div>
      <StyledCalendarConnectionBox>
        {!googleCalendarConnected.status ? (
          <CalendarBtn
            label="Google Calendar"
            description="Manage the meetings you want Jamy to process"
            onClick={() => onClickGoogle()}
            icon={googleCalendar}
          ></CalendarBtn>
        ) : (
          <CalendarConnected
            calendar={googleCalendarConnected.calendar}
            status={googleCalendarConnected.status}
            icon={googleCalendar}
            handleEditConfiguration={() => setIsEditGoogle(!isEditGoogle)}
            isEdit={isEditGoogle}
            form={formEdit}
            onFinish={onFinish}
            preferences={preferencesGoogle}
            disconnectAccount={() => disconnectAcount('google')}
            onCancel={() => setIsEditGoogle(false)}
          />
        )}

        {!msCalendarConnected.status ? (
          <CalendarBtn
            description="Manage the meetings you want Jamy to process"
            label="Outlook Calendar"
            onClick={() => onClicMS()}
            icon={outlookCalendar}
          ></CalendarBtn>
        ) : (
          <CalendarConnected
            calendar={msCalendarConnected.calendar}
            status={msCalendarConnected.status}
            icon={outlookCalendar}
            handleEditConfiguration={() => setIsEditGoogle(!isEditGoogle)}
            isEdit={isEditGoogle}
            form={formEdit}
            onFinish={onFinish}
            preferences={preferencesGoogle}
            disconnectAccount={() => disconnectAcount('microsoft')}
            onCancel={() => setIsEditGoogle(false)}
          />
        )}
      </StyledCalendarConnectionBox>
    </StyledCalendarConnectionContainer>
  )
}

export default CalendarConnectionContainer