import React, { useContext, useEffect, useState } from 'react'
import CalendarGeneralConnected from '../../components/calendarGeneralConnected'
import { useForm } from 'antd/es/form/Form'
import { toast } from 'react-toastify'
import CalendarsDataService from '../../services/calendars'
import { optionsMeetings } from '../../components/calendarConnectedEdit/options'
import UserDataService from '../../services/users'
import JamyContext from '../../context/jamyContext'

type PropsCalendarConfiguration = {
  show: boolean
}

const CalendarConfiguration: React.FC<PropsCalendarConfiguration> = ({
  show
}) => {
  const [outlookMail, setOutlookMail] = useState('')
  const [googleMail, setGoogleMail] = useState('')
  const { user, getUser } = useContext(JamyContext)
  const [formEdit] = useForm()
  const [preferencesGoogle, setPreferencesGoogle] = useState<any>()
  const calendarDataService = new CalendarsDataService()
  const userDataService = new UserDataService()

  useEffect(() => {
    getCalendarConfiguration()
  }, [])

  const onFinish = (data: any) => {
    calendarDataService
      .updatePreferences({
        preferences: optionsMeetings.filter((x) => x.value === data[0].value)[0]
          .preferences
      })
      .then(() => {
        toast.success('Preferences updated', { theme: 'colored' })
      })
      .catch((error: any) => {
        console.error(error)
      })
    getCalendarConfiguration()
    getUser()
  }

  const getCalendarConfiguration = () => {
    calendarDataService
      .getCalendar()
      .then((response) => {
        if (response.data) {
          setOutlookMail(
            response.data.connections.filter(
              (x: any) => x.platform === 'microsoft'
            )[0].email
          )
          setGoogleMail(
            response.data.connections.filter(
              (x: any) => x.platform === 'google'
            )[0].email
          )

          const preferencesTemp = {
            record_non_host: response.data.preferences.record_non_host,
            record_recurring: response.data.preferences.record_recurring,
            record_external: response.data.preferences.record_external,
            record_internal: response.data.preferences.record_internal,
            record_confirmed: response.data.preferences.record_confirmed,
            record_only_host: response.data.preferences.record_only_host
          }
          setPreferencesGoogle({
            ...getValuePreferences(preferencesTemp),
            bot_name: `${user?.first_name || 'Jamy.ai'}'s Meeting Assistant`
          })
        }
      })
      .catch(() => {
        toast.error('Error, try again.', { theme: 'colored' })
      })
  }

  const getValuePreferences = (preferences: any) => {
    const preferenceValue = optionsMeetings.find(
      (x) => JSON.stringify(x.preferences) === JSON.stringify(preferences)
    )
    return {
      ...preferenceValue,
      preferences: {
        ...preferenceValue?.preferences,
        bot_name: `${user?.first_name || 'Jamy.ai'}'s Meeting Assistant`
      }
    }
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'end' }} hidden={!show}>
      <CalendarGeneralConnected
        form={formEdit}
        onFinish={onFinish}
        preferences={preferencesGoogle ? preferencesGoogle : ''}
        outlookMail={outlookMail}
        googleMail={googleMail}
      />
    </div>
  )
}

export default CalendarConfiguration