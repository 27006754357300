import React from 'react'
import HelmetComponent from '../../../components/helmet'
import { LayoutMain } from '../../../layout'
import MeetingDetialsContainer from '../../../containers/meetingsDetailsContainer'
import { MeetingProvider } from '../../../context/meetingContext'

const MeetingDetailPage: React.FC = () => {
  return (
    <LayoutMain title={'Report'}>
      <HelmetComponent title="Report"></HelmetComponent>
      <MeetingProvider>
        <MeetingDetialsContainer />
      </MeetingProvider>
    </LayoutMain>
  )
}

export default MeetingDetailPage
