import React, { useContext, useEffect, useState } from 'react'
import PricingTable from '../../components/pricingTable'
import { Button, Modal, Segmented, Tag } from 'antd'
import Receipt from '../../components/receipt'
import PrincingDataService from '../../services/pricing'
import { toast } from 'react-toastify'
import JamyContext from '../../context/jamyContext'

const PricingTableContainer: React.FC = () => {
  const { getOrgInformation } = useContext(JamyContext)
  const [statePlan, setStatePlan] = useState('')
  const [productName, setProductName] = useState('')
  const [recurring, setRecurring] = useState('Monthly')
  const [pricingTables, setpricingTables] = useState<Princing.Table[]>([])
  const [pricingTablesConverted, setpricingTablesConverted] = useState<
    Princing.Table[]
  >([])
  const [pricingPlan, setPricingPlan] = useState<Princing.Plan>()
  const [upgradeOverview, setUpgradeOverview] =
    useState<Princing.UpgradeOverview>()
  const tablePricingDataService = new PrincingDataService()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeAppSumoTier, setActiveAppSumoTier] = useState<number | null>(null);

  useEffect(() => {
    getPricingPlan()
  }, [])

  useEffect(() => {
    if (!pricingPlan) return;
  
    tablePricingDataService
      .getCustomPricingTable()
      .then((response) => {
        const activeTables = response.data.filter((x) => x.active === true);
        setpricingTables(activeTables);
  
        if (!pricingPlan.id) return;
  
        const selectedTable = activeTables.find(
          (x) => x.id === pricingPlan?.product_id
        );
  
        if (!selectedTable || !selectedTable.prices) return;
  
        // Check if it's an AppSumo plan by looking for "lifetime" interval
        const activeAppSumoProduct = response.data.find(
          (product) => product.appsumo_license_active === true
        );
        
        if (activeAppSumoProduct) {
          setRecurring("lifetime");
          // Store the active AppSumo tier
          setActiveAppSumoTier(activeAppSumoProduct.appsumo_license_tier);
        }        
        
        else {
          // Regular Stripe Monthly/Yearly Pricing
          const selectedPrice = selectedTable.prices.find(
            (x) => x.id === pricingPlan?.price_id
          );
    
          if (selectedPrice) {
            setRecurring(
              selectedPrice.recurring.interval === "month"
                ? "Monthly"
                : "Yearly"
            );
          } else {
          }
        }
      })
      .catch((err) => {
        toast.error("Error getting pricing tables, try again.", {
          theme: "colored",
        });
      });
  }, [pricingPlan]);  

  useEffect(() => {
    if (pricingTables.length > 0) {
      addPriceInTable()
    }
  }, [recurring, pricingTables])

  const addPriceInTable = () => {    
    const addPriceDefault: Princing.Table[] = []
    const pricingTablesFilter: Princing.Table[] =
      recurring === 'Yearly'
        ? pricingTables.filter((x) => x.name !== 'Pay-per-minute')
        : pricingTables
  
    pricingTablesFilter.forEach((element) => {
      const monthlyPrice = element.prices.find(
        (x) => x.recurring.interval === 'month'
      )
      const yearlyPrice = element.prices.find(
        (x) => x.recurring.interval === 'year'
      )
      const lifetimePrice = element.prices.find(
        (x) => x.recurring.interval === 'lifetime'
      )
  
      let convertedPrice = ''
  
      if (recurring === 'Monthly' && monthlyPrice) {
        convertedPrice = (monthlyPrice.unit_amount / 100).toString()
      } else if (recurring === 'Yearly' && yearlyPrice) {
        convertedPrice = (yearlyPrice.unit_amount / 100 / 12)
          .toFixed(2)
          .toString()
      } else if (lifetimePrice) {
        convertedPrice = (lifetimePrice.unit_amount / 100).toString()
      }
    
      addPriceDefault.push({
        ...element,
        default_price_converted: convertedPrice
      })
    });    
    // Ensure React state updates
    setpricingTablesConverted(addPriceDefault);
  }  

  const getPricingPlan = () => {
    tablePricingDataService
      .getPricingPlan()
      .then((response) => {
        setPricingPlan(response.data)
      })
      .catch((error) => {
        toast.error('Error getting current plan, try again.', {
          theme: 'colored'
        })
      })
  }

  const onClickTable = (productName: string, type: string, priceID: string) => {
    setProductName(priceID);

    const isAppSumoPlan = pricingTables.some(
      (table) => table.id === productName && 
                 table.prices.some((price) => price.recurring.interval === "lifetime")
    );

    if (isAppSumoPlan) {
      window.open("https://appsumo.com/account/products/", "_blank");
      return;
    }

    switch (type) {
      case 'subscribe':
        setStatePlan('subscribe');
        tablePricingDataService
          .getCheckout(priceID)
          .then((response) => {
            window.open(response.data.checkout_session_url, "_blank");
          })
          .catch(() => {
            toast.error('Error getting checkout, try again.', { theme: 'colored' });
          });
        break;
      case 'upgrade':
        setStatePlan('upgrade');
        setIsModalOpen(true);
        tablePricingDataService
          .getUpgradeOverview(productName, priceID)
          .then((response) => {
            setUpgradeOverview(response.data);
          })
          .catch((error) => {
            toast.error('Error getting Upgrade Overview, try again.', { theme: 'colored' });
          });
        break;
      default:
        break;
    }
  };

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setUpgradeOverview(undefined)
    setIsModalOpen(false)
  }

  const onClickUpgradePlan = (new_price_id: string) => {
    // Check if the selected plan is an AppSumo plan
    const isAppSumoPlan = pricingTables.some(
      (table) => table.id === new_price_id && 
                 table.prices.some((price) => price.recurring.interval === "lifetime")
    );

    if (isAppSumoPlan) {
      window.open("https://appsumo.com/account/products/", "_blank");
      return;
    }

    switch (statePlan) {
      case 'subscribe':
        tablePricingDataService
          .getCheckout(productName)
          .then((response) => {
            window.open(response.data.checkout_session_url, "_blank");
          })
          .catch(() => {
            toast.error('Error getting checkout, try again.', { theme: 'colored' });
          });
        break;
      case 'upgrade':
        setUpgradeOverview(undefined);

        tablePricingDataService
          .updatePricingPlan({ new_price_id: productName })
          .then(() => {
            toast.success('Plan was updated successfully', { theme: 'colored' });
            getPricingPlan();
            getOrgInformation();
            handleCancel();
          })
          .catch(() => {
            toast.error('Error upgrading plan', { theme: 'colored' });
          });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {!pricingTables.some((table) => 
          table.prices.some((price) => price.recurring.interval === "lifetime")
        ) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Segmented
            value={recurring}
            options={['Monthly', 'Yearly']}
            onChange={(value) => {
              setRecurring(value.toString())
            }}
          />
          <Tag
            color="orange"
            style={{
              height: '25px',
              width: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
              fontWeight: '700',
              marginLeft: '15px'
            }}
          >
            Save 25%
          </Tag>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around'
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-evenly',
          flexDirection: 'row-reverse'
        }}
      >
        {pricingTablesConverted.map((table, index) => {
          return (
            <PricingTable
              pricingPlanSelected={pricingPlan}
              item={table}
              recurring={recurring}
              onClickUpgrade={onClickTable}
              activeAppSumoTier={activeAppSumoTier}
              key={index}
            />
          )
        })}
        <Modal
          title=""
          open={isModalOpen}
          footer={null}
          onCancel={handleCancel}
          onOk={handleOk}
          width={'70%'}
        >
          <Receipt
            onClickCancel={handleCancel}
            upgradeOverview={upgradeOverview}
            onClickUpgradePlan={onClickUpgradePlan}
          />
        </Modal>
      </div>
    </div>
  )
}

export default PricingTableContainer
