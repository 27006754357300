import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  StyledIntegrationBtn,
  StyledIntegrationsContainer,
  StyledIntegrationsImg,
  StyledItemContainter,
  StyledItemContent,
  StyledItemIcon,
  StyledItemIcons,
  StyledItemText,
  StyledItemUserContainer
} from './styledComponents'
import editIcon from '../../assets/icons/edit.svg'
import assign from '../../assets/icons/assign.svg'
import deleteIcon from '../../assets/icons/delete.svg'
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Tooltip
} from 'antd'
import { useForm } from 'antd/es/form/Form'
import UserTask from '../userTask'
import { StyledH1V2 } from '../../styles/styledComponents'
import moment from 'moment'
import ReactPlayer from 'react-player'
import { StyledPillStatus } from '../shared/pills/styledComponents'
import trello from '../../assets/integrations/trello.svg'
import monday from '../../assets/integrations/monday.svg'
import upnify from '../../assets/integrations/upnify.svg'
import JamyContext from '../../context/jamyContext'
import UnlockFeature from '../unlockFeature'
import { toast } from 'react-toastify'
import { useParams, useSearchParams } from 'react-router-dom'
import MeetingsDataService from '../../services/meetings'
import { useMeetingActions } from '../../hooks/useMeetingActions'
import { useMeeting } from '../../context/meetingContext'
import CommentsContainer from '../../containers/commentsContainer'
type PropsItem = {
  item: any
  nameInput: string
  title: string
  position: number
  onHandleIntegration?: (app: string) => void
  onFinish: (data: any, index: number) => void
  onClicFinishDescription?: (data: any) => void
  onDelete: (index: number) => void
  onChecked?: (e: any) => void
  onDeleteResponsible?: (
    responsible: Meetings.Responsible,
    position: number
  ) => void
  responsibles: any[]
  isDone?: boolean
}

const ItemTask: React.FC<PropsItem> = ({
  title,
  position,
  nameInput,
  responsibles,
  onDeleteResponsible,
  onHandleIntegration,
  onChecked,
  isDone,
  onFinish,
  onDelete,
  onClicFinishDescription,
  item
}) => {
  const { user, permissions, isAnonymous } = useContext(JamyContext)
  const [form] = useForm()
  const [checkForm] = useForm()
  const [descriptionForm] = useForm()
  const [openAssign, setOpenAssign] = useState(false)
  const [playing] = useState(false)
  const playerRef = useRef<any>()
  const [descriptionEdit, setDescriptionEdit] = useState(false)
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loadingAssign, setLoadingAssign] = useState(false)
  const { meetingItem } = useMeeting()
  const { meeting_id } = useParams()
  const { recipients, tasks, meeting_file } = meetingItem || {}

  const meetingDataService = new MeetingsDataService()
  const { getMeetingDetail } = useMeetingActions()

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('item_id')) {
      setOpen(searchParams.get('item_id') === item.id)
    }
  }, [])

  const hide = () => {
    setOpenAssign(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpenAssign(newOpen)
  }

  const showDrawer = () => {
    if (
      permissions &&
      permissions.includes(
        nameInput === 'topic' ? 'topics_detail' : 'tasks_detail'
      )
    ) {
      setOpen(true)
    } else {
      setShowModal(!showModal)
    }
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleAssign = async (data: any) => {
    setLoadingAssign(true)
    try {
      const { responsible } = data
      const dataRequest = {
        section: 'tasks',
        item: {
          ...meetingItem?.tasks[position - 1],
          responsibles: getResponsiblesInfo(responsible, position)
        }
      }
      await updateMeetingBySection(dataRequest, 'Tasks')
      setOpenAssign(false)
    } catch (error) {
      toast.error('Error assigning task', {
        theme: 'colored',
        toastId: 'error-assign-task'
      })
    } finally {
      setLoadingAssign(false)
    }
  }

  const getResponsiblesInfo = (arr: string[], position: number) => {
    if (!recipients) return []
    let responsibles: Meetings.Responsible[] = []

    arr.forEach((element) => {
      responsibles.push({
        email: element,
        last_name: recipients.filter((x) => x.email === element)
          ? recipients.filter((x) => x.email === element)[0].last_name
          : '',
        first_name: recipients.filter((x) => x.email === element)
          ? recipients.filter((x) => x.email === element)[0].first_name
          : ''
      })
    })

    return responsibles
  }

  const updateMeetingBySection = async (data: any, arg: string) => {
    if (meeting_id) {
      await meetingDataService
        .updateMeetingBySection(data, meeting_id)
        .then(() => {
          getMeetingDetail(meeting_id)
          toast.success(`${arg} have been updated`, { theme: 'colored' })
        })
        .catch((error) => {
          toast.error(`${arg} haven't been updated correctly`, {
            theme: 'colored'
          })
        })
    }
  }

  const renderOptionsToAssign = () => {
    return (
      <Form
        form={form}
        layout="vertical"
        style={{ width: '300px' }}
        onFinish={handleAssign}
      >
        <Form.Item name={'responsible'}>
          <Select
            mode="multiple"
            size="middle"
            placeholder="Select Responsible"
            style={{ width: '100%' }}
            options={optionsResponsibles(recipients ? recipients : [])}
            defaultValue={optionsResponsibles(responsibles ? responsibles : [])}
          />
        </Form.Item>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
        >
          <Button
            type="default"
            htmlType="button"
            onClick={() => {
              setOpenAssign(false)
            }}
            disabled={loadingAssign}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingAssign}
            disabled={loadingAssign}
          >
            Update
          </Button>
        </div>
      </Form>
    )
  }

  const optionsResponsibles = (responsiblesArray: Meetings.Recipients[]) => {
    const options: { value: string; label: React.ReactNode }[] = []

    responsiblesArray.forEach((element) => {
      const label = element.first_name + ' ' + element.last_name
      options.push({
        value: element.email,
        label: <div>{label.length > 3 ? label : element.email}</div>
      })
    })
    return options
  }

  const renderStatus = (status: string) => {
    switch (status) {
      case 'done':
        return <StyledPillStatus status="done">DONE</StyledPillStatus>
      default:
        return <StyledPillStatus status="to_do">TO DO</StyledPillStatus>
    }
  }

  const renderItem = () => {
    return (
      <StyledItemContent>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
            <div>
              <Form form={checkForm}>
                <Form.Item
                  name={'checkTask'}
                  valuePropName="checked"
                  style={{ margin: 0 }}
                >
                  <Checkbox
                    style={{ margin: '10px', width: '30px' }}
                    defaultChecked={isDone}
                    onChange={(e) => {
                      if (onChecked) {
                        onChecked(e)
                        checkForm.setFieldValue('checkTask', e.target.checked)
                      }
                    }}
                  ></Checkbox>
                </Form.Item>
              </Form>
            </div>
            <StyledItemContainter onClick={edit ? undefined : showDrawer}>
              <StyledItemText isDone={isDone} hidden={edit ? true : false}>
                {title}
              </StyledItemText>
              <Form
                form={form}
                hidden={edit ? false : true}
                style={{ width: '90%', marginTop: '30px' }}
                onFinish={(data) => {
                  onFinish(data, position - 1)
                  setEdit(false)
                }}
              >
                <Row gutter={16}>
                  <Col span={22}>
                    <Form.Item name={nameInput}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        +
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </StyledItemContainter>
            <StyledItemUserContainer onClick={edit ? undefined : showDrawer}>
              <p style={{ color: 'white' }}>.</p>
            </StyledItemUserContainer>
            <StyledItemUserContainer
              onClick={edit ? undefined : showDrawer}
              isAnonymous={isAnonymous}
            >
              {responsibles &&
                responsibles.map((responsible, index) => {
                  return (
                    <UserTask
                      isEdit={open}
                      user={responsible}
                      key={index}
                      onDelete={() =>
                        onDeleteResponsible
                          ? onDeleteResponsible(responsible, position)
                          : null
                      }
                      isAnonymous={isAnonymous}
                    ></UserTask>
                  )
                })}
            </StyledItemUserContainer>
            {!isAnonymous && (
              <StyledItemIcons>
                <div hidden={open ? true : false}>
                  <Popover
                    key={1}
                    content={renderOptionsToAssign()}
                    title="Assign task to"
                    trigger="click"
                    placement="left"
                    open={openAssign}
                    onOpenChange={handleOpenChange}
                  >
                    <StyledItemIcon src={assign} hidden={false} />
                  </Popover>
                </div>
                <StyledItemIcon
                  hidden={edit ? true : false}
                  src={editIcon}
                  onClick={() => {
                    form.setFieldValue(nameInput, title)
                    setEdit(true)
                  }}
                />
                <StyledItemIcon
                  src={deleteIcon}
                  onClick={() => onDelete(position)}
                />
              </StyledItemIcons>
            )}
          </div>
          {permissions && permissions.includes('integrations') && (
            <StyledIntegrationsContainer>
              {user?.integrations.trello_user && (
                <Tooltip
                  placement="bottom"
                  title={
                    item.integrations
                      ? item.integrations.trello
                        ? 'Open task in Trello'
                        : 'Add task to Trello'
                      : 'Add task to Trello'
                  }
                >
                  <StyledIntegrationBtn
                    onClick={() =>
                      item.integrations
                        ? item.integrations.trello
                          ? window.open(item.integrations.trello)
                          : onHandleIntegration
                          ? onHandleIntegration('Trello')
                          : undefined
                        : onHandleIntegration
                        ? onHandleIntegration('Trello')
                        : undefined
                    }
                  >
                    <StyledIntegrationsImg src={trello} />
                    <p>
                      {item.integrations
                        ? item.integrations.trello
                          ? 'Open'
                          : 'Add'
                        : 'Add'}
                    </p>
                  </StyledIntegrationBtn>
                </Tooltip>
              )}

              {user?.integrations.monday_user && (
                <Tooltip
                  placement="bottom"
                  title={
                    item.integrations
                      ? item.integrations.monday
                        ? 'Open task in Monday'
                        : 'Add task to Monday'
                      : 'Add task to Monday'
                  }
                >
                  <StyledIntegrationBtn
                    onClick={() =>
                      item.integrations
                        ? item.integrations.monday
                          ? window.open(item.integrations.monday)
                          : onHandleIntegration
                          ? onHandleIntegration('Monday')
                          : undefined
                        : onHandleIntegration
                        ? onHandleIntegration('Monday')
                        : undefined
                    }
                  >
                    <StyledIntegrationsImg src={monday} />
                    <p>
                      {item.integrations
                        ? item.integrations.monday
                          ? 'Open'
                          : 'Add'
                        : 'Add'}
                    </p>
                  </StyledIntegrationBtn>
                </Tooltip>
              )}

              {user?.integrations.upnify_user && (
                <Tooltip
                  placement="bottom"
                  title={
                    item.integrations
                      ? item.integrations.upnify
                        ? 'Open task in Upnify'
                        : 'Add task to Upnify'
                      : 'Add task to Upnify'
                  }
                >
                  <StyledIntegrationBtn
                    onClick={() =>
                      item.integrations
                        ? item.integrations.upnify
                          ? window.open(item.integrations.upnify)
                          : onHandleIntegration
                          ? onHandleIntegration('Upnify')
                          : undefined
                        : onHandleIntegration
                        ? onHandleIntegration('Upnify')
                        : undefined
                    }
                  >
                    <StyledIntegrationsImg src={upnify} />
                    <p>
                      {item.integrations
                        ? item.integrations.upnify
                          ? 'Open'
                          : 'Add'
                        : 'Add'}
                    </p>
                  </StyledIntegrationBtn>
                </Tooltip>
              )}
            </StyledIntegrationsContainer>
          )}
        </div>
      </StyledItemContent>
    )
  }

  return (
    <React.Fragment>
      {renderItem()}
      <Drawer
        title={''}
        onClose={onClose}
        open={open}
        size="large"
        width={1000}
      >
        <div style={{ float: 'right' }}>
          {item.status ? renderStatus(item.status) : null}
        </div>
        <StyledH1V2>{nameInput.toLocaleUpperCase()}</StyledH1V2>
        {renderItem()}
        {item.description && (
          <React.Fragment>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <StyledH1V2>Description</StyledH1V2>
              {!isAnonymous && (
                <img
                  src={editIcon}
                  alt="edit"
                  style={{ cursor: 'pointer', margin: '0 10px' }}
                  onClick={() => {
                    setDescriptionEdit(!descriptionEdit)
                    descriptionForm.setFieldValue(
                      'description',
                      item.description
                    )
                    descriptionForm.setFieldValue('index', position)
                  }}
                />
              )}
            </div>
            <p
              style={{ lineHeight: '20px', fontSize: '15px' }}
              hidden={descriptionEdit}
            >
              {item.description}
            </p>
            <Form
              form={descriptionForm}
              onFinish={(data) => {
                if (onClicFinishDescription) {
                  onClicFinishDescription(data)
                }
                setDescriptionEdit(false)
              }}
              hidden={!descriptionEdit}
            >
              <Form.Item name={'description'}>
                <Input.TextArea
                  size="middle"
                  style={{ height: '200px' }}
                ></Input.TextArea>
              </Form.Item>
              <Form.Item name={'index'} hidden={true}>
                <Input
                  size="middle"
                  style={{ height: '200px' }}
                  type="number"
                ></Input>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </React.Fragment>
        )}
        <StyledH1V2>Quotes</StyledH1V2>
        {item.quotes &&
          item.quotes.map((quote: any, index: number) => {
            return (
              <div
                key={index}
                style={{ display: 'flex', marginBottom: '20px' }}
              >
                <div>
                  <p
                    style={{
                      marginRight: '10px',
                      color: '#975DB8',
                      cursor: 'pointer',
                      fontWeight: 'bold'
                    }}
                    onClick={() => {
                      playerRef.current.seekTo(quote.timestamp, 'seconds')
                    }}
                  >
                    {moment.utc(quote.timestamp * 1000).format('HH:mm:ss')}
                  </p>
                </div>
                <div>
                  <p style={{ color: '#5E6DB8', fontWeight: 'bold' }}>
                    {quote.speaker}
                  </p>
                  <p style={{ fontStyle: 'italic' }}>"{quote.quote}..."</p>
                </div>
              </div>
            )
          })}
        <div>
          <ReactPlayer
            ref={playerRef}
            url={meeting_file}
            playing={playing}
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
        <div>
          <CommentsContainer item={item} />
        </div>
      </Drawer>

      <UnlockFeature
        namePlan="Pro"
        featureName={`${nameInput} details`}
        showModal={showModal}
        handleShowModal={() => {
          setShowModal(!showModal)
        }}
        handleUpgrade={() => console.log('upgrade')}
      />
    </React.Fragment>
  )
}

export default ItemTask
