import React, { useContext, useState } from 'react'
import {
  StyledDayCalendarContent,
  StyledDayText,
  StyledMeetingItem,
  StyledMeetingItemContent,
  StyledMeetingItemContentLink,
  StyledMeetingMoreItem
} from './styledComponents'
import moment from 'moment'
import { Modal } from 'antd'
import PopUpMeetingDetail from '../popUpMeetingDetail'
import CalendarsDataService from '../../services/calendars'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

type PropsDayCalendar = {
  getMeets: () => void
  day?: number | string
  dayName?: string
  meetings: Meetings.Results[]
  date: string
}

const DayCalendar: React.FC<PropsDayCalendar> = ({
  day,
  date,
  dayName,
  meetings,
  getMeets
}) => {
  const [loading, setLoading] = useState(false)
  const isTheDay = date === moment().format('YYYY-MM-DD') ? true : false
  const calendarDataService = new CalendarsDataService()

  const getColorItem = (status: string) => {
    switch (status) {
      case 'sent':
        return '#3ba933'
      case 'processed':
        return '#975db8'
      case 'not_to_be_processed':
        return '#989b9d'
      case 'to_be_processed':
        return '#007bcd'
    }
  }

  const onClickItem = (status: string, id: string) => {
    switch (status) {
      case 'processing':
        break
      default:
        setIsModalOpen(true)
        break
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [meetingSelected, setMeetingSelected] = useState<Meetings.Results>()

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onChangeSwitch = (
    meeting_id?: string,
    override_should_record?: boolean
  ) => {
    setLoading(true)
    if (meeting_id) {
      calendarDataService
        .processMeeting(
          meeting_id,
          override_should_record ? override_should_record : false
        )
        .then(() => {
          getMeets()
          handleCancel()
          setLoading(false)
          toast.success(`Process Meeting updated`, { theme: 'colored' })
        })
        .catch((e) => {
          toast.error('Error to activate process meeting', { theme: 'colored' })
        })
    }
  }

  return (
    <>
      <StyledDayCalendarContent>
        <StyledDayText
          to={`day/${date}`}
          target="__blank"
          style={{ backgroundColor: isTheDay ? '#d3d7ff' : 'white' }}
        >
          <label>{dayName}</label>
          <p>{day}</p>
        </StyledDayText>
        {meetings.length > 0 &&
          meetings.map((item, index) => {
            const itemRendered = (
              <StyledMeetingItem color={getColorItem(item.status)}>{`${
                item.start_time
                  ? `${moment(item.start_time).format('hh:mm A')} | `
                  : ''
              } ${item.name}`}</StyledMeetingItem>
            )

            if (item.status === 'sent' || item.status === 'processed') {
              return (
                <StyledMeetingItemContentLink
                  key={index}
                  to={`/meetings/details/${item.id}`}
                  target="__blank"
                  onClick={() => {
                    setMeetingSelected(item)
                  }}
                >
                  {itemRendered}
                </StyledMeetingItemContentLink>
              )
            }

            return (
              <StyledMeetingItemContent
                key={index}
                onClick={() => {
                  setMeetingSelected(item)
                  onClickItem(item.status, item.id)
                }}
              >
                {itemRendered}
              </StyledMeetingItemContent>
            )
          })}

        {meetings.length > 3 && (
          <Link to={`day/${date}`} target="__blank">
            <StyledMeetingMoreItem>
              {meetings.length - 3} more
            </StyledMeetingMoreItem>
          </Link>
        )}
      </StyledDayCalendarContent>
      <Modal
        open={isModalOpen}
        footer={null}
        width={'80%'}
        onCancel={handleCancel}
      >
        <PopUpMeetingDetail
          item={meetingSelected}
          onChange={onChangeSwitch}
          loading={loading}
        />
      </Modal>
    </>
  )
}

export default DayCalendar
