import React, { useState, useEffect, useContext } from 'react';
import PhoneDialer from '../../components/PhoneDialer'
import { LayoutMain } from '../../layout';
import CallsDataService from '../../services/calls';
import { toast } from 'react-toastify';
import { PageContainer, TemplateContainer, TitleContainer, Title, DescriptionContainer, Description, ComponentsContainer, ComponentItem, ComponentTitle, PromptList, PromptItem, PromptText, ContentWrapper, DialerSection, TemplateSection } from './styledComponents';
import TemplateSelect from './components/templateSelect';
import TemplatesDataService from '../../services/templates';
import { useMediaQuery } from 'react-responsive';
import UnlockFeature from '../../components/unlockFeature';
import JamyContext from '../../context/jamyContext';
import { PermissionsList } from '../../hooks/usePermissions/data';
import { useNavigate } from 'react-router-dom';

const PRICE_ID_ADD_ON_PPM = 'price_1Ny2JIEsHvOxhmS5rWT5osYh'

const Call: React.FC = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<Templates.Results>();
  const [twilioToken, setTwilioToken] = useState<string>();
  const [permissionCall, setPermissionCall] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const callsDataService = new CallsDataService();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { org } = useContext(JamyContext)
  const navigate = useNavigate();

  useEffect(() => {
    // Get the Twilio token when the component is mounted
    const fetchTwilioToken = async () => {
      try {
        const response = await callsDataService.generateToken();
        setTwilioToken(response.data.token);
      } catch (error) {
        toast.error(`Error in the call ${error}`, { theme: 'colored', toastId: 'twilio-token-error' });
      }
    };

    fetchTwilioToken();
  }, []);

  useEffect(() => {
    if (!org) return;
    const permission = PermissionsList.find((permission) => {
      return permission.id === org?.pricing_plan?.product_id
    })
    const permissionsAddOn = PermissionsList.find((permission) => {
      return org?.pricing_plan?.add_on?.find((addOn) => addOn.product_id === permission.id)
    })
    
    if (permission?.id === "prod_OlZSQeakjavHVG" || permissionsAddOn?.id === "prod_OlZSQeakjavHVG") {
      setPermissionCall(true)
    } else {
      setPermissionCall(false)
      setShowModal(true)
    }
  }, [org])

  const handleCall = async (phoneNumber: string) => {
    // The call logic is now handled in the PhoneDialer
    console.log('Initializing call to:', phoneNumber, 'with template:', selectedTemplate);
  };
  
  const getTemplate = async (templateId: string) => {

    if (!templateId) {
      setSelectedTemplate(undefined)
      return;
    }
    const templatesDataService = new TemplatesDataService();
    const response = await templatesDataService.getTemplateById(templateId)
    setSelectedTemplate(response.data)
  }
  
  const handleShowModal = () => {
    setShowModal(!showModal)
    navigate('/dashboard')
  }

  return (
    <LayoutMain title="Call">
      <PageContainer>
        <ContentWrapper isMobile={isMobile}>
          <DialerSection
            isMobile={isMobile}
          >
            <PhoneDialer 
              onCall={handleCall}
              selectedTemplate={selectedTemplate?.id}
              twilioToken={twilioToken}
              permissionCall={permissionCall}
            />
          </DialerSection>
          <TemplateSection
            isMobile={isMobile}
          >
            <TemplateSelect
              onChange={(value) => getTemplate(value as string)}
              showSearch={false}
            />
            {selectedTemplate && (
              <TemplateContainer>
                <TitleContainer>
                  <Title>{selectedTemplate?.name}</Title>
                </TitleContainer>
                <DescriptionContainer>
                  <Description>{selectedTemplate?.description}</Description>
                </DescriptionContainer>
                <ComponentsContainer>
                  {selectedTemplate?.components?.map((component) => {
                    if (component.component_type === 'FORM') {
                      return (
                        <ComponentItem key={component.id}>
                          <ComponentTitle>{component.name}</ComponentTitle>
                          <PromptList>
                            {component?.prompt?.prompt?.map((prompt: any, index: number) => (
                              <PromptItem key={index} style={{ '--item-index': index } as React.CSSProperties}>
                                <PromptText>{prompt.name}</PromptText>
                              </PromptItem>
                            ))}
                          </PromptList>
                        </ComponentItem>
                      );
                    }
                  })}
                </ComponentsContainer>
              </TemplateContainer>
            )}
          </TemplateSection>
        </ContentWrapper>
      </PageContainer>
      <UnlockFeature
        namePlan="Pay per minute"
        featureName="Call"
        priceId={PRICE_ID_ADD_ON_PPM}
        showModal={showModal}
        handleShowModal={handleShowModal}
        handleUpgrade={() => { }}
        addOn
      />
    </LayoutMain>
  );
};

export default Call; 