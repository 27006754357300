import { Button, Modal } from 'antd'
import React, { useContext } from 'react'
import { StyledH2V2 } from '../../styles/styledComponents'
import { useNavigate } from 'react-router-dom'
import JamyContext from '../../context/jamyContext'
import PrincingDataService from '../../services/pricing'

type PropsUnlockFeature = {
  namePlan: string
  featureName: string
  showModal: boolean
  handleShowModal: () => void
  handleUpgrade?: () => void
  addOn?: boolean
  priceId?: string
}

const UnlockFeature: React.FC<PropsUnlockFeature> = ({
  featureName,
  showModal,
  namePlan,
  handleShowModal,
  addOn = false,
  priceId
}) => {
  const navigate = useNavigate()
  const { isAnonymous } = useContext(JamyContext)

  const navigateTo = (page: string) => {
    localStorage.removeItem('access-token')
    localStorage.removeItem('is_active')
    navigate(page)
  }

  const pricingService = new PrincingDataService()

  return (
    <Modal
      width={600}
      title={`Unlock ${featureName}`}
      open={showModal}
      onOk={handleShowModal}
      onCancel={handleShowModal}
      footer={[
        <Button key="back" onClick={handleShowModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={async () => {
            if (isAnonymous) {
              navigateTo('/register')
            } else if (addOn) {
              try {
                if (!priceId) {
                  console.error("Error: priceId is required for checkout")
                  return
                }

                const response = await pricingService.getCheckout(priceId, "addon")
                if (response.data && response.data.checkout_session_url) {
                  
                  window.open(response.data.checkout_session_url, '_blank')
                  
                } else {
                  console.error('Checkout URL not found in response')
                }
              } catch (error) {
                console.error('Error getting checkout URL:', error)
              }
            } else {
              navigate('/pricing')
            }
          }}
        >
          {isAnonymous ? 'Register' : addOn ? `Unlock ${featureName} Feature` : `Upgrade to ${namePlan}`}
        </Button>
      ]}
    >
      <div style={{ textAlign: 'center', padding: '20px', margin: '30px 0' }}>
        <StyledH2V2>You've discovered an advanced feature!</StyledH2V2>
        <p style={{ fontSize: '15px' }}>
          {isAnonymous ? (
            'Register to unlock this feature'
          ) : addOn ? (
            <>
              You need the <strong style={{ color: '#5333C1' }}>{namePlan}</strong> Add-On{' '} to unlock this feature
            </>
          ) : (
            <>
              Upgrade to{' '}
              <strong style={{ color: '#5333C1' }}>{namePlan}</strong> to unlock this feature
            </>
          )}
        </p>
      </div>
    </Modal>
  )
}

export default UnlockFeature
