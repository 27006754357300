import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  StyledComponentCommentActionsContent,
  StyledComponentCommentActionsReplay,
  StyledComponentCommentItemAuthor,
  StyledComponentCommentItemAuthorContent,
  StyledComponentCommentItemContent,
  StyledComponentCommentItemDateTime
} from './styledComponents'
import moment from 'moment'
import CommentForm from '../commentForm'
import EmojiPopover from '../emojiPopover'

type PropsCommentItem = {
  item: Comments.Comment
  parent_comment?: string
  getComments: () => void
}

const CommentItem: React.FC<PropsCommentItem> = ({
  item,
  parent_comment,
  getComments
}) => {
  const [isReplying, setIsReplying] = useState(false)
  const highlightMentions = (text: string) => {
    return text.replace(
      /@(\w+)/g,
      '<span style="color: #975EB8; font-weight: bold;">@$1</span>'
    )
  }

  return (
    <div>
      <StyledComponentCommentItemContent>
        <StyledComponentCommentItemAuthorContent>
          <StyledComponentCommentItemAuthor>
            {item.author_name}
          </StyledComponentCommentItemAuthor>
          <StyledComponentCommentItemDateTime>
            {moment(item.created_date).format('hh:mm a MMM YY, dddd')}
          </StyledComponentCommentItemDateTime>
        </StyledComponentCommentItemAuthorContent>
        <p
          style={{ lineHeight: '18px', marginTop: '10px', marginBottom: 0 }}
          dangerouslySetInnerHTML={{
            __html: highlightMentions(item.text)
          }}
        />
        <StyledComponentCommentActionsContent>
          <StyledComponentCommentActionsReplay
            onClick={() => setIsReplying(!isReplying)}
          >
            Reply
          </StyledComponentCommentActionsReplay>
          <EmojiPopover item={item} getComments={getComments} />
        </StyledComponentCommentActionsContent>
        {isReplying && (
          <CommentForm
            itemParsed={item as unknown as Comments.Item}
            parent_comment={parent_comment}
            getComments={getComments}
            setIsReplying={setIsReplying}
          />
        )}
      </StyledComponentCommentItemContent>
    </div>
  )
}

export default CommentItem
