import React, { useContext, useState } from 'react';
import HeaderContainer from '../containers/headerContainer';
import MenuContainer from '../components/menuContainer';
import IntercomComponent from '../components/intercom';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { Container, ContainerContent, MenuIconContainer, StyledContent } from './styledComponents';
import JamyContext from '../context/jamyContext';
const { Content, Sider } = Layout;

const MenuIcon = ({ isCollapsed }: { isCollapsed: boolean }) => {
  return (
    <MenuIconContainer isCollapsed={isCollapsed}>
      {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </MenuIconContainer>
  )
}

export const LayoutMain = ({ children, title }: any) => {
  const { isCollapsedMenu, setIsCollapsedMenu } = useContext(JamyContext)
  return (
      <Container>
        <Sider
          breakpoint="sm"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            setIsCollapsedMenu(collapsed);
          }}
          width={100}
          trigger={<MenuIcon isCollapsed={isCollapsedMenu} />}
          style={{
            backgroundColor: '#FFF'
          }}
        >
          <MenuContainer />
        </Sider>

          <ContainerContent>
            <HeaderContainer title={title} />
            <StyledContent>
              {children}
            </StyledContent>
          </ContainerContent>

          <IntercomComponent />
      </Container>
  );
};
