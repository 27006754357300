import React from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import { StyledH1, StyledH3 } from '../../styles/styledComponents'
import PricingTableContainer from '../../containers/pricingTableContainer'

export const PricingPage: React.FC = () => {
  return (
    <LayoutMain title={'Pricing'}>
      <HelmetComponent title="Pricing"></HelmetComponent>
      <React.Fragment>
        <div>
          <StyledH1 style={{ margin: 0, textAlign: 'center' }}>
            Never Take Meeting Notes Again
          </StyledH1>
          <StyledH3 style={{ margin: '10px 0', textAlign: 'center' }}>
            Find the Perfect Plan
          </StyledH3>
        </div>

        <PricingTableContainer />
      </React.Fragment>
    </LayoutMain>
  )
}
