import styled from 'styled-components'
import { Menu } from 'antd'


export const StyledMenuContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #FFF;
  border: none;
`
export const StyledMenuSide = styled.div`
  background-color: rgba(83, 51, 193, 0.1);
  margin: 20px 10px 0 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`
export const StyledMenuLogo = styled.img`
  width: 50px;
  margin: 10px;
`

export const StyledMenuIcon = styled.img`
  width: 25px;
  margin: 20px 0;
`
export const StyledMenuItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 0 0;
`
export const StyledMenuLink = styled.div`
  cursor: pointer;
`
export const StyledMenuLogout = styled.img`
  position: absolute;
  cursor: pointer;
  bottom: 50px;
`
