import React, { useState, useEffect, useContext } from 'react';
import { Device, Call } from '@twilio/voice-sdk';
import { DialerContainer, PhoneDisplay, DialPad, DialButton, CallButton, RecordingIndicator, CallButtonContainer, BackButtonIcon, CallStatusContainer, TimerText, CallStatusText, PhoneInputWrapper } from './styledComponents';
import { Select, Tooltip} from 'antd'
import { InfoCircleOutlined} from '@ant-design/icons'
import { toast } from 'react-toastify';
import { PhoneFilled, CloseOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import countryCodes from '../../utils/countryCode';
import JamyContext from '../../context/jamyContext';


interface PhoneDialerProps {
  onCall: (phoneNumber: string) => void;
  selectedTemplate?: string;
  permissionCall: boolean;
  twilioToken?: string;
}

const PhoneDialer: React.FC<PhoneDialerProps> = ({ onCall, selectedTemplate, twilioToken, permissionCall }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [isDialing, setIsDialing] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [device, setDevice] = useState<Device | null>(null);
  const [activeCall, setActiveCall] = useState<Call | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const durationIntervalRef = React.useRef<NodeJS.Timeout>();
  const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | null>(null);
  const { isCollapsedMenu } = useContext(JamyContext)

  const isMobile = useMediaQuery({ maxWidth: 768 });
  useEffect(() => {
    if (twilioToken && permissionCall) {
      navigator?.mediaDevices?.getUserMedia({ audio: true })
        .then(() => {
          const newDevice = new Device(twilioToken, {
            codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
          });
          
          newDevice.register();

          newDevice.on('registered', () => {
            console.log('Twilio Device is registered');
          });

          newDevice.on('error', (error) => {
            setIsDialing(false);
            console.log("🚀 ~ newDevice.on ~ error:", error)
          });

          newDevice.on('incoming', (call) => {
            console.log('Incoming call from:', call.parameters.From);
          });

          setDevice(newDevice);
        })
        .catch((error) => {
          toast.error('Could not access the microphone. Please verify the browser permissions.', { theme: 'colored', toastId: 'microphone-error' });
        });

      return () => {
        device?.destroy();
      };
    }
  }, [twilioToken]);

  const startTimer = () => {
    setCallDuration(0);
    durationIntervalRef.current = setInterval(() => {
      setCallDuration(prev => prev + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (durationIntervalRef.current) {
      clearInterval(durationIntervalRef.current);
    }
  };

  const formatDuration = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleButtonPress = (num: string) => {
    if (num === '0') {
      const timer = setTimeout(() => {
        setPhoneNumber(prev => prev.slice(0, -1) + '+');
      }, 1000);
      setLongPressTimer(timer);
    }
  };

  const handleButtonRelease = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
  };

  const handleNumberClick = (num: string) => {
    if (phoneNumber.length < 15) {
      setPhoneNumber(prev => prev + num);
    }
  };

  const handleCall = async () => {
    if (!device) {
      console.log("🚀 ~ handleCall ~ device:", device)
      return;
    }
    
    if (phoneNumber.length < 10) {
      toast.error('Phone number is not valid', { theme: 'colored', toastId: 'phone-number-error' });
      return;
    }
    if (!countryCode) {
      toast.error('Phone number must include the "+" for the country code', { theme: 'colored', toastId: 'phone-number-error' });
      return;
    }

    if (activeCall) {
      activeCall.disconnect();
      setIsDialing(false);
      setIsRecording(false);
      stopTimer();
      setActiveCall(null);
    } else if (countryCode && phoneNumber) {
      setIsDialing(true);
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        
        const call = await device.connect({
          params: {
            To: countryCode + phoneNumber?.trim(),
            template: selectedTemplate || ''
          }
        });
        
        call.on('accept', () => {
          setActiveCall(call);
          setIsDialing(false);
          setIsRecording(true);
          startTimer();
        });

        call.on('disconnect', () => {
          setActiveCall(null);
          setIsRecording(false);
          stopTimer();
        });

        call.on('error', (error) => {
          console.log("🚀 ~ call.on ~ error:", error)
          setIsDialing(false);
          setActiveCall(null);
        });

        onCall(phoneNumber);
      } catch (error: any) {
        setIsDialing(false);
        console.log("🚀 ~ handleCall ~ error:", error)
      }
    }
  };
  
  // Erase the last number of the phone number
  const handleBackButton = () => {
    if (phoneNumber.length > 1) {
      setPhoneNumber(prev => prev.slice(0, -1));
    } else if (countryCode) {
      setCountryCode('');
      setPhoneNumber('');
    } else if (phoneNumber.length === 1) {
      setPhoneNumber('');
    }
  }

  const dialPadNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

  return (
    <DialerContainer isMobile={isMobile}>
      <CallStatusContainer>
        {isDialing && <CallStatusText>Calling...</CallStatusText>}
        {activeCall && (
          <>
            {isRecording && <RecordingIndicator>🔴 Recording</RecordingIndicator>}
            <TimerText>{formatDuration(callDuration)}</TimerText>
          </>
        )}
      </CallStatusContainer>
      <PhoneInputWrapper>
        <div style={{ 
          position: 'relative', 
          width: '100%', 
          display: 'flex', 
          gap: '10px', 
          alignItems: 'center',
          marginBottom: '20px'
        }}>
          <div style={{ 
            display: 'flex',
            flexDirection: isMobile ? (isCollapsedMenu ? 'row' : 'column') : 'row',
            gap: '4px', 
            width: '100%',
            alignItems: isMobile ? (isCollapsedMenu ? 'center' : 'flex-start') : 'center'
          }}>
            <Select
              style={{ 
                maxHeight: isMobile ? '52px' : '64px',
                minHeight: isMobile ? '52px' : '64px',
                minWidth: isMobile ? '80px' : '120px',
                maxWidth: isMobile ? '80px' : '120px',
                fontSize: isMobile ? (isCollapsedMenu ? '11px' : '9px') : '20px'
              }}
              options={countryCodes}
              value={countryCode || undefined}
              onChange={(value: any) => {
                setCountryCode(value as string);
              }}
              disabled={isDialing || !!activeCall}
              allowClear={false}
              placeholder="Code"
              aria-placeholder='Code Country'
              showSearch
              filterOption={(input, option) =>
                (option?.label ? option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false)
              }
              labelRender={({label}) => {
                return <div style={{ 
                  width: '100%', 
                  padding: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: isMobile ? (isCollapsedMenu ? '11px' : '9px') : '20px'
                }}>{label?.toString().split(' ')[0]}</div>
              }}
              dropdownStyle={{
                width: '15em',
              }}
            />
            <PhoneDisplay
              isMobile={isMobile}
              isCollapsed={isCollapsedMenu}
              value={
                countryCode ? countryCode + phoneNumber : phoneNumber
              }
              readOnly={isDialing || !!activeCall}
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d+\-]/g, '');
                // Si el valor pegado comienza con + y es diferente al valor actual
                if (value.startsWith('+') && value !== (countryCode + phoneNumber)) {
                  // Filtrar los códigos de país que coincidan con el inicio del valor ingresado
                  const matchingCodes = countryCodes
                    .filter(code => value.startsWith(code.value))
                    .sort((a, b) => b.value.length - a.value.length);
                  
                  if (matchingCodes.length > 0) {
                    const foundCode = matchingCodes[0];
                    setCountryCode(foundCode.value);
                    setPhoneNumber(value.slice(foundCode.value.length));
                    return;
                  }
                }
                
                // Si el valor es más corto que el código de país actual
                if (countryCode && value.length < countryCode.length) {
                  setCountryCode('');
                  setPhoneNumber(value);
                  return;
                }
                
                // Comportamiento normal para otros casos
                if (countryCode) {
                  const currentNumber = value.replace(countryCode, '');
                  setPhoneNumber(currentNumber);
                } else {
                  setPhoneNumber(value);
                }
              }}
              placeholder="+525512345678"
              type="tel"
              pattern="[\+0-9\-]{11,}"
              minLength={11}
              maxLength={15}
            />
          </div>

          <Tooltip
            placement="topRight"
            title={
              <div>
                <p>
                  Select the country code of the phone number you want to call.
                </p>
                <p>
                  <strong>For example, for a MX phone number you should select:</strong>
                </p>
                <p>
                  +52 followed by the 10 digit phone number.
                </p>
                <p>
                  <strong>For a US phone number:</strong>
                </p>
                <p>
                  +1 followed by the 10 digit phone number.
                </p>
              </div>
            }
          >
            <InfoCircleOutlined
              style={{
                position: 'absolute',
                right: '0',
                top: '0%',
                transform: 'translateY(-50%)',
                color: 'black',
                cursor: 'pointer',
                fontSize: isMobile ? '14px' : '18px',
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: '5px',
              }}
            />
          </Tooltip>
        </div>
      </PhoneInputWrapper>
      <DialPad>
        {dialPadNumbers.map(num => (
          <DialButton 
            key={num} 
            onClick={() => handleNumberClick(num)}
            onMouseDown={() => handleButtonPress(num)}
            onMouseUp={handleButtonRelease}
            onMouseLeave={handleButtonRelease}
            onTouchStart={() => handleButtonPress(num)}
            onTouchEnd={handleButtonRelease}
            isMobile={isMobile}
            isCollapsed={isCollapsedMenu}
          >
            {num}
            {num === '0' && <span style={{ fontSize: '14px', marginTop: '-5px' }}>+</span>}
          </DialButton>
        ))}
      </DialPad>
      <CallButtonContainer>
        <div style={{ flex: 1, display: 'flex' }} />
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CallButton 
            onClick={handleCall} 
            disabled={!phoneNumber && !activeCall}
            isActive={!!activeCall}
          >
            {activeCall ? <CloseOutlined style={{ fontSize: '24px' }} /> : <PhoneFilled style={{ rotate: '90deg', fontSize: '24px' }} />}
          </CallButton>
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {(phoneNumber || countryCode) && <BackButtonIcon onClick={handleBackButton} />}
        </div>
      </CallButtonContainer>
    </DialerContainer>
  );
};

export default PhoneDialer; 